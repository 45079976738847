import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {post, getHost} from '../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../component/Layout";
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";

const filesize = require('filesize');
const dayjs = require('dayjs');

const dateFormat = 'YYYY-MM-DD';
const requestDateFormat = 'YYYY-MM-DD';

class EventLogList extends React.Component {
    constructor(props) {
        super(props);

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);

        let defaultCreatedStart = new Date(dayjs().subtract(1, 'day').format(dateFormat));
        let defaultCreatedEnd = new Date();

        if (params.get("createdStart") != "") {
            defaultCreatedStart = params.get("createdStart")
        }
        if (params.get("createdEnd") != "") {
            defaultCreatedEnd = params.get("createdEnd")
        }

        this.state = {
            eventLogs: [],
            errorMessage: "",
            filter: {
                Event: params.get("event"),
                Keys: {
                    accountId: params.get("keyAccountId"),
                    partnerId: params.get("keyPartnerId"),
                    invoiceId: params.get("keyInvoiceId"),
                    email: params.get("keyEmail"),
                    emailKey: params.get("keyEmailKey"),
                    emailTemplateId: params.get("keyEmailTemplateId"),
                    externalId: params.get("keyExternalId"),
                    phone: params.get("phone"),
                },
                Actor: params.get("actor"),
                ActorId: params.get("actorId"),
                CreatedStart: defaultCreatedStart,
                CreatedEnd: defaultCreatedEnd,
            },
            filterHelper: {
                CreatedStartEnabled: false,
                CreatedEndEnabled: false,
            }
        };
        this.loadData = this.loadData.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.filterHelperChange = this.filterHelperChange.bind(this);
        this.CreatedStartChange = this.CreatedStartChange.bind(this);
        this.CreatedEndChange = this.CreatedEndChange.bind(this);
    }

    async loadData(filter) {
        const postData = {
            Filter: filter,
            FilterOptions: {}
        }
        const [res, err] = await post(this, "/eventLog/search", postData);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({eventLogs: res.data.List});
    }

    async componentDidMount() {
        await this.loadData({...this.state.filter});
    }

    onlyIfEnabled(filter, field, condition) {
        if (!condition) {
            delete filter[field]
        }
    }

    async handleSearch(event) {
        event.preventDefault();

        const filter = {...this.state.filter}; // we copy object because we edit the values

        this.onlyIfEnabled(filter, "CreatedStart", this.state.filterHelper.CreatedStartEnabled)
        this.onlyIfEnabled(filter, "CreatedEnd", this.state.filterHelper.CreatedEndEnabled)

        await this.loadData(filter);
    }

    filterChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.filter;
        data[name] = value;

        this.setState({filter: data});
    }

    filterHelperChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.filterHelper;
        data[name] = value;

        this.setState({filterHelper: data});
    }

    CreatedStartChange(newDate) {
        const data = this.state.filter;
        data.CreatedStart = newDate;
        this.setState({filter: data});
    }

    CreatedEndChange(newDate) {
        const data = this.state.filter;
        data.CreatedEnd = newDate;
        this.setState({filter: data});
    }

    render() {
        return <Layout activeMenu="eventLog">
            <h1>EventLogs ({this.state.eventLogs ? this.state.eventLogs.length : 0})</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            <Form className="form-fieldnames" onSubmit={e => this.handleSearch(e)}>
                <Row>
                    <Col sm>
                        <Form.Group controlId="formStatus">
                            <Form.Label column>Event:</Form.Label>
                            <Form.Control as="select" name="Event" defaultValue={this.state.filter.Event}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option>paymentSuccess</option>
                                <option>paymentFailed</option>
                                <option>paymentSkipped</option>
                                <option>paymentAuthorizationTriggered</option>
                                <option>invoiceCreated</option>
                                <option>emailSentSuccess</option>
                                <option>emailSentFailed</option>
                                <option>emailSentSkipped</option>
                                <option>goPremium</option>
                                <option>voucherActivation</option>
                                <option>voucherActivationFailed</option>
                                <option>phoneVerificationStart</option>
                                <option>phoneVerificationSuccess</option>
                                <option>phoneVerificationWrongCode</option>
                                <option>phoneAuthStart</option>
                                <option>phoneAuthSuccess</option>
                                <option>phoneAuthWrongCode</option>
                                <option>phoneDeleted</option>
                                <option>sendSms</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formKeys">
                            <Form.Label column>Search keys:</Form.Label>
                            <div>AccountId: <Form.Control type="text" name="keysAccountId"
                                                          defaultValue={this.state.filter.Keys.accountId}
                                                          onChange={this.filterChange}/></div>
                            <div>InvoiceId: <Form.Control type="text" name="keysInvoiceId"
                                                          defaultValue={this.state.filter.Keys.invoiceId}
                                                          onChange={this.filterChange}/></div>
                            <div>Email: <Form.Control type="text" name="keysEmail"
                                                      defaultValue={this.state.filter.Keys.email}
                                                      onChange={this.filterChange}/></div>
                            <div>EmailKey: <Form.Control type="text" name="keysEmailKey"
                                                         defaultValue={this.state.filter.Keys.emailKey}
                                                         onChange={this.filterChange}/></div>
                            <div>EmailTemplateId: <Form.Control type="text" name="keysEmailTemplateId"
                                                                defaultValue={this.state.filter.Keys.emailTemplateId}
                                                                onChange={this.filterChange}/></div>
                            <div>ExternalId: <Form.Control type="text" name="keysExternalId"
                                                           defaultValue={this.state.filter.Keys.externalId}
                                                           onChange={this.filterChange}/></div>
                            <div>Phone: <Form.Control type="text" name="phone"
                                                      defaultValue={this.state.filter.Keys.phone}
                                                      onChange={this.filterChange}/></div>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formCreatedStart">{this.state.filterHelper.CreatedStartEnabled}
                            <Form.Label column>EventLog date start:</Form.Label>
                            <Form.Check type="checkbox" name="CreatedStartEnabled"
                                        defaultChecked={this.state.filterHelper.CreatedStartEnabled}
                                        onChange={this.filterHelperChange}/>
                            {this.state.filterHelper.CreatedStartEnabled &&
                            <DatePicker
                                selected={this.state.filter.CreatedStart}
                                onChange={this.CreatedStartChange}
                                name="EventDatedStart"
                                dateFormat="yyyy.MM.dd"
                            />
                            }
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formCreatedEnd">
                            <Form.Label column>EventLog date end:</Form.Label>
                            <Form.Check type="checkbox" name="CreatedEndEnabled"
                                        defaultChecked={this.state.filterHelper.CreatedEndEnabled}
                                        onChange={this.filterHelperChange}/>
                            {this.state.filterHelper.CreatedEndEnabled &&
                            <DatePicker
                                selected={new Date(this.state.filter.CreatedEnd)}
                                onChange={this.CreatedEndChange}
                                name="CreatedEnd"
                                dateFormat="yyyy.MM.dd"
                            />
                            }
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formActor">
                            <Form.Label column>Actor:</Form.Label>
                            <Form.Control as="select" name="Actor" defaultValue={this.state.filter.Actor}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option>system</option>
                                <option>admin</option>
                                <option>user</option>
                                <option>test</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formActorId">
                            <Form.Label column>ActorId:</Form.Label>
                            <Form.Control type="text" name="ActorId"
                                          defaultValue={this.state.filter.ActorId}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Button type="submit" variant="primary">Search</Button>
                    </Col>
                </Row>
            </Form>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Created</th>
                    <th>Event</th>
                    <th>Keys</th>
                    <th>Data</th>
                    <th>Actor</th>
                    <th>ActorId</th>
                </tr>
                </thead>
                <tbody>
                {this.state.eventLogs && this.state.eventLogs.map((value, index) => {
                    return <tr key={index}>
                        <td>{value.Created}</td>
                        <td><a href={"/eventLog/?event=" + value.Event}>{value.Event}</a></td>
                        <td>{value.Keys && Object.keys(value.Keys).map(function (key) {
                            if (key == "accountId") {
                                return <div><a
                                    href={"/eventLog/?keyAccountId=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                    &nbsp;<b><a href={"/account/" + value.Keys[key]}
                                                target="_blank">[account <>&#8599;</>]</a></b>
                                </div>
                            } else if (key == "partnerId") {
                                return <div><a
                                    href={"/eventLog/?keyPartnerId=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                    &nbsp;<b><a href={"/partner/" + value.Keys[key] + "/view/"}
                                                target="_blank">[partner <>&#8599;</>]</a></b>
                                </div>
                            } else if (key == "invoiceId") {
                                return <div><a
                                    href={"/eventLog/?keyInvoiceId=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                    &nbsp;<b><a href={"/invoice/" + value.Keys[key] + "/view/"}
                                                target="_blank">[invoice <>&#8599;</>]</a></b>
                                </div>
                            } else if (key == "email") {
                                return <div><a
                                    href={"/eventLog/?keyEmail=" + value.Keys[key]}>{key}: {value.Keys[key]}</a></div>
                            } else if (key == "emailKey") {
                                return <div><a
                                    href={"/eventLog/?keyEmailKey=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                </div>
                            } else if (key == "emailTemplateId") {
                                return <div><a
                                    href={"/eventLog/?keyEmailTemplateId=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                </div>
                            } else if (key == "externalId") {
                                return <div><a
                                    href={"/eventLog/?keyExternalId=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                </div>
                            } else if (key == "phone") {
                                return <div><a
                                    href={"/eventLog/?phone=" + value.Keys[key]}>{key}: {value.Keys[key]}</a>
                                </div>
                            } else {
                                return <div>{key}: {value.Keys[key]}</div>
                            }
                        })}</td>
                        <td>
                            <pre>{JSON.stringify(value.Data, null, 2)}</pre>
                        </td>
                        <td><a href={"/eventLog/?actor=" + value.Actor}>{value.Actor}</a></td>
                        <td><a href={"/eventLog/?actorId=" + value.ActorId}>{value.ActorId}</a></td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Layout>;
    }
}

export default EventLogList
