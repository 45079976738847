import React from 'react'
import Layout from "../../component/Layout";
import AccountNav from "../../component/AccountNav";
import {get, post} from "../../utils/request";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "react-bootstrap/FormLabel";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Alert from "react-bootstrap/Alert";
import "react-datepicker/dist/react-datepicker.css";
import {humanize, unHumanize} from "../../utils/humanReadableSize";

var dayjs = require('dayjs');
const filesize = require('filesize');

const apiDateFormat = 'YYYY-MM-DD';

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class AccountPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loaded: false,
            accountData: {
                Name: '',
                Email: '',
            },
            usage: 0,
            usageLimitInput: "",
            data: {
                paid: false,
                paidStart: new Date(),
                allowExtraDownload: false,
                usageLimit: "",
                hasHadUsage: 0,
                trialExpire: new Date(),
            },
            successMessage: '',
            errorMessage: '',
        };
        this.loadData = this.loadData.bind(this);
        this.loadAccount = this.loadAccount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePaidStart = this.handleChangePaidStart.bind(this);
        this.handleChangeTrialExpire = this.handleChangeTrialExpire.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUsageLimit = this.handleChangeUsageLimit.bind(this);

    }

    async loadAccount() {
        const [res, err] = await get(this, "/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({accountData: res.data});
    }

    async loadData() {
        const [res, err] = await get(this, "/account/accountPackage/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        const data = res.data;

        this.setState({data: data});
        this.setState({usageLimitInput: humanize(data.usageLimit)});
        this.setState({usage: data.usage});
    }

    async componentDidMount() {
        await this.loadData();
        await this.loadAccount();
        this.setState({loaded: true});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    async handleChangeUsageLimit(event) {
        const value = event.target.value;
        const data = this.state.data;
        data.usageLimit = unHumanize(value);
        this.setState({data: data});
    }

    async handleChangePaidStart(date) {
        const data = this.state.data;
        data.paidStart = date;

        this.setState({data: data})
    }

    async handleChangeTrialExpire(date) {
        const data = this.state.data;
        data.trialExpire = date;

        this.setState({data: data})
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const path = "/account/accountPackage/" + this.state.id;
        const data = this.state.data;
        data.usageLimit = data.usageLimit.toString();

        data.paidStart = dayjs(data.paidStart).format(apiDateFormat)
        if (data.trialExpire !== "") {
            data.trialExpire = dayjs(data.trialExpire).format(apiDateFormat)
        }

        const [_, err] = await post(this, path, data);

        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 422:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 409:
                        this.setState({errorMessage: "Trial expire date can not be in the past"});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({successMessage: "Account updated!"});
        await this.loadData();
    }

    render() {
        if (!this.state.loaded) {
            return <div>loading data...</div>
        }
        return <Layout activeMenu="account">
            <AccountNav activeKey="package" id={this.state.id}/>
            <div style={divStyle}>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                    <h1>Package</h1>
                    <br/>
                    <p><b>Name: </b>{this.state.accountData.name}</p>
                    <p><b>Email: </b>{this.state.accountData.email}</p>
                    <Form.Group as={Row} controlId="formBasicCheckbox">
                        <Form.Label column sm="3">Paid</Form.Label>
                        <Col sm="9">
                            <Form.Check type="checkbox" name="paid" defaultChecked={this.state.data.paid}
                                        onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    {this.state.data.paid && <Form.Group as={Row} controlId="formPaidStart">
                        <Form.Label column sm="3">Paid start</Form.Label>
                        <Col sm="9">
                            <DatePicker
                                selected={new Date(this.state.data.paidStart)}
                                onChange={this.handleChangePaidStart}
                                name="paidStart"
                                dateFormat="yyyy.MM.dd"
                            />
                        </Col>
                    </Form.Group>}
                    <Form.Group as={Row} controlId="formBasicCheckbox">
                        <Form.Label column sm="3">Allow extra download</Form.Label>
                        <Col sm="9">
                            <Form.Check type="checkbox" name="allowExtraDownload"
                                        defaultChecked={this.state.data.allowExtraDownload}
                                        onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    {!this.state.data.paid && <Form.Group as={Row} controlId="formTrialExpire">
                        <Form.Label column sm="3">Trial expire</Form.Label>
                        <Col sm="9">
                            <DatePicker
                                selected={this.state.data.trialExpire !== "" && new Date(this.state.data.trialExpire)}
                                onChange={this.handleChangeTrialExpire}
                                name="trialExpire"
                                dateFormat="yyyy.MM.dd"
                            />
                        </Col>
                    </Form.Group>}
                    <Form.Group as={Row} controlId="formUsageLimit">
                        <Form.Label column sm="3" no>Usage limit</Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="usageLimitInput" defaultValue={this.state.usageLimitInput}
                                          onChange={this.handleChangeUsageLimit}/>
                        </Col>
                        <FormLabel>
                            <br/>
                            Current usage: <b>{filesize(this.state.data.usage)}</b>
                            {this.state.data.hasHadUsage && this.state.data.usage === 0 ? " (but has used)" : ""}
                            <br/>
                            Current limit: <b>{humanize(this.state.data.usageLimit)}</b>
                            , used <b>{Math.round(this.state.data.usage / this.state.data.usageLimit * 100)}%</b>
                        </FormLabel>
                    </Form.Group>
                    <Button type="submit" variant="primary">Save</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default AccountPackage