import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import LocationSelector from "../../component/LocationSelector";
import {Spinner} from "react-bootstrap";

class TaskList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: "",
            loaded: false,
            tasks: [],
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/storage/task");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({loaded: true});
        this.setState({errorMessage: ""});
        this.setState({tasks: res.data.Tasks});
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="task"/>
            <h1>Tasks</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {!this.state.loaded &&
            <>
                <Spinner animation="border" variant="primary"/> Data not loaded...
                <br/><br/>
            </>
            }
            {this.state.loaded && <>
                <Table bordered hover>
                    <thead className="thead-light">
                    <tr>
                        <th>Location</th>
                        <th>TaskId</th>
                        <th>BucketId</th>
                        <th>Status</th>
                        <th>Next run</th>
                        <th>Created</th>
                        <th>Started</th>
                        <th>Ended</th>
                        <th>Num runs</th>
                        <th>Last error</th>
                        <th>Triggered by</th>
                        <th>End reason</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.tasks !== null && this.state.tasks.map((value, index) => {
                        let status = <span className="badge badge-pill badge-warning">{value.status}</span>;
                        if (value.status === "started") {
                            status = <span className="badge badge-pill badge-danger">{value.status}</span>;
                        } else if (value.status === "ended") {
                            status = <span className="badge badge-pill badge-success">{value.status}</span>;
                        }

                        let endReason = "";
                        if (value.endReason === "success") {
                            endReason = <span className="badge badge-pill badge-success">{value.endReason}</span>;
                        } else {
                            endReason = <span className="badge badge-pill badge-danger">{value.endReason}</span>;
                        }

                        let triggeredBy = value.actor + "/" + value.actorId
                        if (value.actor === "admin") {
                            triggeredBy = <a href={"/admin/" + value.actorId}>{value.actor}/{value.actorId}</a>
                        }

                        return <tr key={index}>
                            <td>{value.locationUid}</td>
                            <td>{value.taskId}</td>
                            <td>{value.bucketId}</td>
                            <td>{status}</td>
                            <td>{value.nextRun}</td>
                            <td>{value.created}</td>
                            <td>{value.started}</td>
                            <td>{value.ended}</td>
                            <td>{value.numRuns}</td>
                            <td>{value.lastError}</td>
                            <td>{triggeredBy}</td>
                            <td>{endReason}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </>
            }
        </Layout>;
    }
}

export default TaskList