import React from 'react'
import {get} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert, Spinner} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

class NodeView extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            id: this.props.match.params.id,
            location: location,
            loaded: false,
            data: {
                Services: {
                    StorageDrive: {},
                }
            },
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/node/" + this.state.id + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({loaded: true});
        this.setState({errorMessage: ""});
        this.setState({data: res.data});
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="node"/>
            <div style={divStyle}>
                {this.state.errorMessage &&
                <Alert>
                    {this.state.errorMessage}
                </Alert>
                }
                <h1>Node {this.state.data.Uuid}</h1>
                {!this.state.loaded &&
                    <>
                        <Spinner animation="border" variant="primary"/>Data not loaded...
                        <br/><br/>
                    </>
                }
                {this.state.loaded && <>
                <Table responsive="sm" bordered className="node-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>NodeId</td>
                        <td>{this.state.data.NodeId}</td>
                    </tr>
                    <tr>
                        <td>Uuid</td>
                        <td>{this.state.data.Uuid}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{this.state.data.Address}</td>
                    </tr>
                    <tr>
                        <td>Services / StorageDrive</td>
                        <td>
                            {this.state.data.Services.StorageDrive.Online &&
                            <span className="badge badge-pill badge-success">Online</span>}
                            {!this.state.data.Services.StorageDrive.Online &&
                            <span className="badge badge-pill badge-warning">Offline</span>}
                        </td>
                    </tr>
                    </tbody>
                </Table>


                <h2>Drives in node</h2>
                <Table bordered hover>
                    <thead className="thead-light">
                    <tr>
                        <th>DriveId</th>
                        <th>Online</th>
                        <th>NodeAddress</th>
                        <th>Size</th>
                        <th>Uuid</th>
                        <th>ContainerId</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.data.Drives && this.state.data.Drives.map((drive, index) => {
                        return <tr>
                            <td><a href={"/storage/drive/" + drive.DriveId + "/view?location=" + this.state.location}>{drive.DriveId}</a></td>
                            <td>
                                {drive.Online && <span className="badge badge-pill badge-success">Online</span>}
                                {!drive.Online && <span className="badge badge-pill badge-warning">Offline</span>}
                            </td>
                            <td>{drive.NodeAddress}</td>
                            <td>{filesize(drive.Size)}</td>
                            <td>{drive.Uuid}</td>
                            <td>
                                {drive.ContainerId  === "000000000000000000000000" &&
                                    <i>none</i>
                                }
                                {drive.ContainerId !== "000000000000000000000000" &&
                                    <a href={"/storage/container/" + drive.ContainerId  + "/view?location=" + this.state.location}>{drive.ContainerId}</a>
                                }
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                </>
                }
            </div>
        </Layout>;
    }
}

export default NodeView
