import React from 'react'
import Layout from "../../component/Layout";
import AccountNav from "../../component/AccountNav";
import {get, post} from "../../utils/request";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import "react-datepicker/dist/react-datepicker.css";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class AccountPartnerSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loaded: false,
            account: {
                partner:
                    {externalId: ''}
            },
            data: {},
            successMessage: '',
            errorMessage: '',
        };
        this.loadAccount = this.loadAccount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadAccount() {
        const [res, err] = await get(this, "/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({account: res.data.partner});
    }

    async componentDidMount() {
        await this.loadAccount();
        this.setState({loaded: true});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const path = "/account/" + this.state.id + "/partnerSettings";
        const data = this.state.data;

        const [_, err] = await post(this, path, data);

        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        this.setState({errorMessage: "Error: Not found"});
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({successMessage: "Account partner settings updated!"});
        await this.loadAccount();
    }

    render() {
        if (!this.state.loaded) {
            return <div>loading data...</div>
        }

        return <Layout activeMenu="account">
            <AccountNav activeKey="partnerSettings" id={this.state.id}/>
            <div style={divStyle}>
                <h1>Account partner settings</h1>
                <br/>
                    <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                        {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                        {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                        <Form.Group as={Row} controlId="formExternalId">
                            <Form.Label column sm="3">External ID</Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" name="externalId" defaultValue={this.state.account.externalId}
                                              onChange={this.handleChange}/>
                            </Col>
                        </Form.Group>
                        <Button type="submit" variant="primary">Save</Button>
                    </Form>
            </div>
        </Layout>;
    }
}

export default AccountPartnerSettings