const filesize = require("filesize");

export function unHumanize(text) {
    if(parseInt(text,10).toString()===text){
        return text;
    }
    var powers = {'K': 1, 'M': 2, 'G': 3, 'T': 4};
    var regex = /(\d+(?:\.\d+)?)\s?(K|M|G|T)?B?/i;
    var res = regex.exec(text);
    if (!res || res.length < 3 || !res[2]) {
        return text;
    }
    return res[1] * Math.pow(1024, powers[res[2].toUpperCase()]);
}

export function humanize(text) {
    const parsed = parseInt(text, 10);
    if (isNaN(parsed)) {
        return "Please use correct format";
    }
    return filesize(parsed);
}
