import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import AccountNav from "../../component/AccountNav";

class AccountStatistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            registration: {},
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({registration: res.data.registration});
    }

    async componentDidMount() {
        await this.loadData();
        this.setState({loaded: true});
    }

    render() {
        if (!this.state.loaded) {
            return <div>loading data...</div>
        }
        return <Layout activeMenu="account">
            <AccountNav activeKey="statistics" id={this.state.id}/>
            <div>
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                <h1>Statistics for user</h1>
                <h2>Signup from</h2>
                <Table responsive="sm" bordered>
                    <tbody>
                    <tr>
                        <td>IP from</td>
                        <td>{this.state.registration.ipData.continentName} / {this.state.registration.ipData.countryName} / {this.state.registration.ipData.regionName} / {this.state.registration.ipData.city}</td>
                    </tr>
                    <tr>
                        <td>Came from Google ads</td>
                        <td>
                            {this.state.registration.source.hasGoogleClick && <span className="badge badge-pill badge-success">Yes</span>}
                            {!this.state.registration.source.hasGoogleClick && <span className="badge badge-pill badge-warning">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>Came from Facebook ads</td>
                        <td>
                            {this.state.registration.source.hasFbClick && <span className="badge badge-pill badge-success">Yes</span>}
                            {!this.state.registration.source.hasFbClick && <span className="badge badge-pill badge-warning">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>UserAgent</td>
                        <td>{this.state.registration.userAgent}</td>
                    </tr>
                    </tbody>
                </Table>

                <h2>Referrals</h2>
                {!this.state.registration.source.clicks && <div>No referrals found</div>}
                <Table bordered hover>
                    <tbody>
                    {this.state.registration.source.clicks && this.state.registration.source.clicks.map((value, index) => {
                        return <tr>
                            <td>
                                <div>At: {value.timestamp}</div>
                                <div>From: {value.source}</div>
                                <div>To: {value.target}</div>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        </Layout>;
    }
}

export default AccountStatistics