import React from 'react';
import Button from 'react-bootstrap/Button';
import {get, post} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import LocationNav from "../../component/LocationNav";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

class LocationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                locationId: '',
                status: '',
                uid: '',
                domain: '',
            },
            s3Keys: 0,
            errorMessage: "",
            successMessage: "",
        };
        this.loadData = this.loadData.bind(this);
        this.resyncKeys = this.resyncKeys.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async resyncKeys() {
        const [res, err] = await post(this, "/location/" + this.state.data.locationId + "/triggerS3KeysSync");
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request, invalid format locationId"});
                        return;
                    case 404:
                        this.setState({errorMessage: "Location not found"});
                        return;
                    case 500:
                        this.setState({errorMessage: "Oops, something went wrong"});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({s3Keys: res.data.numS3KeysSyncsTriggered});
        this.setState({successMessage: "Resyncing " + this.state.s3Keys + " S3 keys to this location"});

    }

    async resyncUsers() {
        const [res, err] = await post(this, "/location/" + this.state.data.locationId + "/triggerUsersSync");
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request, invalid format locationId"});
                        return;
                    case 404:
                        this.setState({errorMessage: "Location not found"});
                        return;
                    case 500:
                        this.setState({errorMessage: "Oops, something went wrong"});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({users: res.data.numUsersSyncsTriggered});
        this.setState({successMessage: "Resyncing " + this.state.users + " users to this location"});

    }

    async loadData() {
        const [res, err] = await get(this, "/location/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    render() {
        const { data, errorMessage, id, successMessage } = this.state;

        return <Layout activeMenu="location">
            <LocationNav activeKey="view" id={id}/>
            <div style={divStyle}>
                {errorMessage &&
                    <Alert variant="danger">
                        {errorMessage}
                    </Alert>
                }
                    {successMessage &&
                        <Alert variant="success">
                            {successMessage}
                        </Alert>
                    }
                <h1>Location view</h1>
                <Table responsive="sm" bordered className="partner-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>LocationId</td>
                        <td>{data.locationId}</td>
                    </tr>
                    <tr>
                        <td>Domain</td>
                        <td>{data.domain}</td>
                    </tr>
                    <tr>
                        <td>UID</td>
                        <td>{data.uid}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{data.status}</td>
                    </tr>
                    <tr>
                        <td>Cert Subject</td>
                        <td>{data.certSubject}</td>
                    </tr>
                    <tr>
                        <td>Cert DNS Names</td>
                        <td>{data.certDnsNames}</td>
                    </tr>
                    <tr>
                        <td>Cert valid not before</td>
                        <td>{data.certValidNotBefore}</td>
                    </tr>
                    <tr>
                        <td>Cert valid not after</td>
                        <td>{data.certValidNotAfter}</td>
                    </tr>
                    </tbody>
                </Table>
                    <Button variant="outline-primary" href={"/location"}>Back to list</Button>
                    <Button variant="outline-primary" href={"/location/" + data.locationId + "/edit"}>Edit</Button>
                <Button variant="primary" onClick={e => this.resyncKeys(e)}>Re-sync all S3 Keys to this location</Button>
                <Button variant="primary" onClick={e => this.resyncUsers(e)}>Re-sync all users to this location</Button>
            </div>
        </Layout>;
    }
}

export default LocationView
