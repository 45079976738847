import React from 'react'
import Button from 'react-bootstrap/Button';

import {get, getHost, post} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import {CountryByCode} from "../../component/CountryByCode";
import PartnerNav from "../../component/PartnerNav";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

class PartnerView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                partnerId: '',
                created: '',
                createdBy: '',
                name: '',
                email: '',
                emailForBilling: '',
                countryCode: '',
                address: '',
                registrationNumber: '',
                vatNumber: '',
                status: '',
                discountPerc: 0,
                invoiceComment: '',
                invoiceDueDateDays: '',
            },
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    render() {
        let status = <span className="badge badge-pill badge-warning">{this.state.data.status}</span>;
        if (this.state.data.status === "active") {
            status = <span className="badge badge-pill badge-success">{this.state.data.status}</span>;
        }

        return <Layout activeMenu="partner">
            <PartnerNav activeKey="view" id={this.state.id}/>
            <div style={divStyle}>
                {this.state.errorMessage &&
                    <Alert>
                        {this.state.errorMessage}
                    </Alert>
                }
                <h1>Partner {this.state.data.Name}</h1>
                <Table responsive="sm" bordered className="partner-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>PartnerId</td>
                        <td>{this.state.data.partnerId}</td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td>{this.state.data.created}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{this.state.data.name}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{this.state.data.email}</td>
                    </tr>
                    <tr>
                        <td>Email for billing</td>
                        <td>{this.state.data.emailForBilling}</td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>{this.state.data.countryCode} / {CountryByCode(this.state.data.countryCode)}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{this.state.data.address}</td>
                    </tr>
                    <tr>
                        <td>Registration number</td>
                        <td>{this.state.data.registrationNumber}</td>
                    </tr>
                    <tr>
                        <td>VAT code</td>
                        <td>{this.state.data.vatNumber}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{status}</td>
                    </tr>
                    <tr>
                        <td>Discount %</td>
                        <td>{this.state.data.discountPerc}%</td>
                    </tr>
                    <tr>
                        <td>Invoice comment</td>
                        <td>{this.state.data.invoiceComment}</td>
                    </tr>
                    <tr>
                        <td>Invoice due date days</td>
                        <td>{this.state.data.invoiceDueDateDays}</td>
                    </tr>
                    </tbody>
                </Table>
                <Button variant="outline-primary" href={"/partner/"}>Back to list</Button>
                <Button variant="outline-primary" href={"/partner/" + this.state.id + "/edit"}>Edit</Button>
            </div>
        </Layout>;
    }
}

export default PartnerView
