import React from 'react'
import Tab from 'react-bootstrap/Tab'
import {Nav} from "react-bootstrap";

function InvoiceNav({activeKey, id}) {
    return (
        <Tab.Container >
            <Nav variant="tabs" className="justify-content-center" activeKey={activeKey}>
                <Nav.Item>
                    <Nav.Link active={activeKey === "invoice"} eventKey="invoice" href={"/invoice/" + id + "/view"} >View</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "payment"} eventKey="payment" href={"/invoice/" + id + "/payment"} >Payments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "paymentMake"} eventKey="paymentMake" href={"/invoice/"+ id + "/paymentMake"}>Make payment</Nav.Link>
                </Nav.Item>
             </Nav>
        </Tab.Container>
    );
}

export default InvoiceNav