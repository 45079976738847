import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {get, post} from '../../utils/request'
import Layout from "../../component/Layout";
import InvoiceNav from "../../component/InvoiceNav";
import {Col, Form, Spinner} from "react-bootstrap";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const dayjs = require('dayjs');

const dateFormat = "YYYY-MM-DD";

const divStyle = {
    margin: '40px auto',
    width: '800px'
};

const manualMethod = {
    label: "manual",
    value: "manual"
}

class InvoicePaymentMake extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            selectedInvoice: {
                SourceType: "",
                SourceId: ""
            },
            loaded: false,
            successMessage: "",
            errorMessage: "",
            data: {
                MethodId: "",
                InvoiceIds: [],
                PaymentDate: new Date(),
                Comment: "",
            },
            methods: [],
            invoices: [],
            defaultMethod: {
                value: "",
                label: "",
            },
            defaultInvoice: {
                value: "",
                label: "",
            },
            accountData: {},
            partnerData: {},
        };
        this.loadSelectedInvoice = this.loadSelectedInvoice.bind(this);
        this.loadPaymentMethods = this.loadPaymentMethods.bind(this);
        this.loadAccount = this.loadAccount.bind(this);
        this.loadPartner = this.loadPartner.bind(this);
        this.loadAllSourceUnpaidInvoices = this.loadAllSourceUnpaidInvoices.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMethodChange = this.handleMethodChange.bind(this);
        this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.paymentDateChange = this.paymentDateChange.bind(this);
        this.isManualPayment = this.isManualPayment.bind(this);
    }

    async loadPaymentMethods() {
        const [res, err] = await get(this, "/payment/" + this.state.selectedInvoice.SourceType + "/" + this.state.selectedInvoice.SourceId + "/methods");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        let defaultMethod = ""
        const methods = res.data.Methods.map((value) => {
                let label = "Card ************" + value.Last4
                label += " Expire on " + value.ExpMonth + "/ " + value.ExpYear
                if (value.IsDefault) {
                    label += " [Default]"
                    defaultMethod = {
                        value: value.Id,
                        label: label,
                    }
                }
                return {
                    value: value.Id,
                    label: label
                }
            }
        );
        methods.push(manualMethod);
        this.setState({methods: methods});
        this.setState({defaultMethod: defaultMethod});
    }

    async loadSelectedInvoice() {
        const [res, err] = await get(this, "/invoice/get/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({selectedInvoice: res.data});
    }

    async loadAllSourceUnpaidInvoices() {
        const request = {
            Filter: {
                SourceType: this.state.selectedInvoice.SourceType,
                SourceId: this.state.selectedInvoice.SourceId,
                Paid: false,
            },
        }
        const [res, err] = await post(this, "/invoice/search", request);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        let defaultInvoice = {}
        const invoices = res.data.List.map((value, index) => {
                let label = "Invoice: " + value.InvoiceNumber
                label += ", Date: " + dayjs(value.InvoiceDate).format(dateFormat)
                label += ", Sum: " + value.TotalSum
                if (index === 0) {
                    defaultInvoice = {
                        value: value.Id,
                        label: label,
                    }
                }
                return {
                    value: value.Id,
                    label: label
                }
            }
        );

        this.setState({invoices: invoices});
        this.setState({defaultInvoice: defaultInvoice});
    }

    async loadAccount() {
        const [res, err] = await get(this, "/account/" + this.state.selectedInvoice.SourceId);
        if (err) {
            this.setState({errorMessage: "error loading Account: " + err});
            return
        }
        this.setState({accountData: res.data});
    }

    async loadPartner() {
        const [res, err] = await get(this, "/partner/" + this.state.selectedInvoice.SourceId);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({partnerData: res.data});
    }

    async componentDidMount() {
        await this.loadSelectedInvoice();

        if (this.state.selectedInvoice.SourceType === "account") {
            await this.loadAccount();
        } else if (this.state.selectedInvoice.SourceType === "partner") {
            await this.loadPartner();
        } else {
            this.setState({errorMessage: "Invalid invoice loaded, unknown sourceType '" + this.state.selectedInvoice.SourceType + "'"});
            return
        }

        await this.loadPaymentMethods();
        await this.loadAllSourceUnpaidInvoices();
        this.setState({loaded: true});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleMethodChange(event) {
        const data = this.state.data;
        data.MethodId = event.value;
        this.setState({data: data});
    }

    handleInvoiceChange(name) {
        const data = this.state.data;
        data.InvoiceIds = name.map((item) => item.value);
        this.setState({data: data});
    }

    paymentDateChange(newDate) {
        const data = this.state.data;
        data.PaymentDate = newDate;
        this.setState({data: data});
    }

    isManualPayment() {
        return this.state.data.MethodId === "manual";
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const path = "/payment/" + this.state.selectedInvoice.SourceType + "/" + this.state.selectedInvoice.SourceId + "/makePayment";
        const data = this.state.data;
        if (this.isManualPayment()) {
            data.PaymentDate = dayjs(data.PaymentDate).format(dateFormat);
        } else {
            delete data.Comment;
            delete data.PaymentDate;
        }

        const [res, err] = await post(this, path, data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 422:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                }
            }
            this.setState({errorMessage: "Error: " + err});
            return;
        }

        const status = res.data.Status
        const extraMessage = res.data.ExtraMessage
        if (status === "success") {
            this.setState({successMessage: "Payment done! " + extraMessage});
            return
        } else if (status === "authenticationRequired") {
            // todo - change this to successMessage once we can send user to confirm the payment
            this.setState({errorMessage: "Payment not done! Payment needs authorization and user will be asked to do it! " + extraMessage});
            return
        }
        this.setState({errorMessage: "Payment status: " + status + "! " + extraMessage});
    }

    render() {
        return <Layout activeMenu="invoice">
            <InvoiceNav activeKey="paymentMake" id={this.state.id}/>
            <div style={divStyle}>
                <h1>Make a new payment</h1>
                <br/>
                {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

                {this.state.sourceType === "account" && <>
                    <p><b>Created: </b>{this.state.accountData.Created}</p>
                    <p><b>Email: </b>{this.state.accountData.Email}</p>
                    <p><b>Name: </b>{this.state.accountData.Name}</p>
                    <p><b>Company: </b>{this.state.accountData.Company}</p></>
                }

                {this.state.sourceType === "partner" && <>
                    <p><b>Created: </b>{this.state.partnerData.Created}</p>
                    <p><b>Email: </b>{this.state.partnerData.Email}</p>
                    <p><b>Name: </b>{this.state.partnerData.Name}</p></>
                }

                <h2>Payment methods</h2>
                {!this.state.loaded &&
                    <>
                        <Spinner animation="border" variant="primary"/> Data not loaded...
                        <br/><br/>
                    </>
                }
                {this.state.loaded && <>
                    <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                        <Form.Group as={Row} controlId="formMethodId">
                            <Form.Label column sm="3">Payment method</Form.Label>
                            <Col sm="9">
                                <Select
                                    options={this.state.methods}
                                    defaultValue={this.state.data.defaultMethod}
                                    onChange={this.handleMethodChange}
                                />
                            </Col>
                        </Form.Group>
                        {this.isManualPayment() &&
                            <>
                                <Form.Group as={Row} controlId="formPaymentDate">
                                    <Form.Label column sm="3">Payment Date</Form.Label>
                                    <Col sm="9">
                                        <DatePicker
                                            selected={new Date(this.state.data.PaymentDate)}
                                            onChange={this.paymentDateChange}
                                            name="paymentDate"
                                            dateFormat="yyyy.MM.dd"
                                            required
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextComment">
                                    <Form.Label column sm="3">Comment</Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            as="textArea"
                                            type="text"
                                            name="Comment"
                                            Rows="1"
                                            onChange={this.handleChange}
                                        >{this.state.data.Comment}</Form.Control>
                                    </Col>
                                </Form.Group>
                            </>
                        }
                        <Form.Group as={Row} controlId="formInvoiceId">
                            <Form.Label column sm="3">Invoice</Form.Label>
                            <Col sm="9">
                                <Select
                                    isMulti
                                    options={this.state.invoices}
                                    defaultValue={this.state.data.defaultInvoice}
                                    onChange={this.handleInvoiceChange}
                                />
                            </Col>
                        </Form.Group>
                        <Button type="submit" variant="primary">Make the payment</Button>
                    </Form>
                </>}
            </div>
        </Layout>;
    }
}

export default InvoicePaymentMake