import React from 'react'
import Button from 'react-bootstrap/Button';

import {get, getHost, post} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import StorageNav from "../../component/StorageNav";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

class VoucherView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {},
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/voucher/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    render() {
        return <Layout activeMenu="voucher">
            <div style={divStyle}>
                {this.state.errorMessage &&
                <Alert>
                    {this.state.errorMessage}
                </Alert>
                }
                <h1>Voucher {this.state.data.VoucherId}</h1>
                <Table responsive="sm" bordered className="voucher-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>VoucherId</td>
                        <td>{this.state.data.VoucherId}</td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td>{this.state.data.Created}</td>
                    </tr>
                    <tr>
                        <td>Created By</td>
                        <td>{this.state.data.CreatedBy}</td>
                    </tr>
                    <tr>
                        <td>Code</td>
                        <td>{this.state.data.Code}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{this.state.data.Name}</td>
                    </tr>
                    <tr>
                        <td>Discount</td>
                        <td>{this.state.data.DiscountPerc}%</td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td>{this.state.data.StartTime}</td>
                    </tr>
                    <tr>
                        <td>End Time</td>
                        <td>{this.state.data.EndTime}</td>
                    </tr>
                    <tr>
                        <td>Account PaidStart From</td>
                        <td>{this.state.data.AccountPaidStartFrom}</td>
                    </tr>
                    <tr>
                        <td>Num Activated</td>
                        <td>{this.state.data.NumActivated}</td>
                    </tr>
                    <tr>
                        <td>Num Allowed Activations</td>
                        <td>{this.state.data.NumAllowedActivations}</td>
                    </tr>
                    </tbody>
                </Table>
                <Button variant="outline-primary" href={"/voucher/"}>Back to list</Button>
                <Button variant="outline-primary" href={"/voucher/" + this.state.data.VoucherId + "/edit"}>Edit</Button>
            </div>
        </Layout>;
    }
}

export default VoucherView
