import React from 'react';
import Tab from 'react-bootstrap/Tab';
import {Nav} from "react-bootstrap";
import {get, post} from "../utils/request";
import dayjs from "dayjs";
import {Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis} from "recharts";
import filesize from "filesize";

const requestDateFormat = 'YYYY-MM-DD';
const chartToolTipFormat = 'YYYY.MM.DD-H';

class UsageGraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountId: this.props.accountId,
            data: null,
            errorMessage: "",
            location: this.props.location,
            height: this.props.height,
            width: this.props.width,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        let path = "/account/userUsageHistory/" + this.state.accountId + "/in/" + this.state.location;
        const data = {
            startDate: dayjs(this.state.startDate).format(requestDateFormat),
            endDate: dayjs(this.state.endDate).format(requestDateFormat),
        };
        const [res, err] = await post(this, path, data);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        let changed = false;
        if (prevProps.location !== this.props.location) {
            this.setState({location: this.props.location});
            changed = true;
        }
        if (prevProps.startDate !== this.props.startDate) {
            this.setState({startDate: this.props.startDate});
            changed = true;
        }

        if (changed) {
            await this.loadData();
        }
    }

    render() {
        if (!this.state.data) {
            return <div>...</div>;
        }

        function formatFunction(bytes) {
            return filesize(bytes);
        }

        function usageToolTipFormatter(bytes) {
            return ["Usage: " + formatFunction(bytes) + " (" + bytes + " B)"];
        }

        function dateFormatter(date) {
            return dayjs(new Date(date.substr(0, 10))).format('MM-DD');
        }

        let storageChartData = [];
        let formattedPeriod = "";

        let pushed = new Set();

        this.state.data.Records.forEach((item) => {
            formattedPeriod = dayjs(new Date(item.Period.substr(0, 10) + " " + item.Period.substr(11, 2) + ":00")).format(chartToolTipFormat);

            if (!pushed.has(formattedPeriod)) {
                pushed.add(formattedPeriod);
                storageChartData.push({name: formattedPeriod, usage: item.Usage});
            }
        });

        return (
            <>
                <AreaChart
                    width={this.state.width}
                    height={this.state.height}
                    data={storageChartData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0
                    }}
                > <Tooltip
                    wrapperStyle={{backgroundColor: "red"}}
                    labelStyle={{color: "#115CA6"}}
                    itemStyle={{color: "#003468"}}
                    formatter={usageToolTipFormatter}
                />
                    <CartesianGrid strokeDasharray="1 1"/>
                    <XAxis dataKey="name"
                           fontFamily="sans-serif"
                           scale="date"
                           tickFormatter={dateFormatter}
                           dy={5}
                           minTickGap={20}
                           tickLine={true}
                           fontSize={12}
                           tick={{fill: "#003468"}}

                    />
                    <YAxis tickFormatter={formatFunction}
                           fontSize={12}
                           tick={{fill: "#003468"}}
                           minTickGap={20}
                           width={80}

                    />
                    <Tooltip/>
                    <defs>
                        <linearGradient id="colorUsage" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#003468" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#003468" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <Area type="monotone" dataKey="usage" stroke="#003468" fillOpacity={1}
                          fill="url(#colorUsage)"/>
                </AreaChart>
            </>
        );

    }
}
export default UsageGraph