import React from 'react'
import Button from 'react-bootstrap/Button';

import {get} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import PartnerNav from "../../component/PartnerNav";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

class PartnerUserView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            partnerId: this.props.match.params.partnerId,
            data: {
                partnerUserId: '',
                created: '',
                name: '',
                email: '',
                status: '',
                rolesEditUsers: false,
            },
            partnerName: '',
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
        this.loadPartner = this.loadPartner.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
        await this.loadPartner();
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/" + this.state.partnerId + "/user/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    async loadPartner() {
        const [res, err] = await get(this, "/partner/" + this.state.partnerId);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({partnerName: res.data.name});
    }

    render() {
        let status = <span className="badge badge-pill badge-warning">{this.state.data.status}</span>;
        if (this.state.data.status === "active") {
            status = <span className="badge badge-pill badge-success">{this.state.data.status}</span>;
        }

        let editUsers = <span className="badge badge-pill badge-warning">No</span>;
        if (this.state.data.rolesEditUsers === true) {
            editUsers = <span className="badge badge-pill badge-success">Yes</span>;
        }

        return <Layout activeMenu="partner">
            <PartnerNav activeKey="users" id={this.state.partnerId}/>
            <div style={divStyle}>
                {this.state.errorMessage &&
                <Alert>
                    {this.state.errorMessage}
                </Alert>
                }
                <h1>Partner user "{this.state.data.name}"</h1>
                <Table responsive="sm" bordered className="partnerUser-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>Id</td>
                        <td>{this.state.data.partnerUserId}</td>
                    </tr>
                    <tr>
                        <td>Partner</td>
                        <td><a href={"/partner/" + this.state.partnerId + "/view"}>{this.state.partnerName}</a></td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td>{this.state.data.created}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{this.state.data.name}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{this.state.data.email}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{status}</td>
                    </tr>
                    <tr>
                        <td>Can edit users</td>
                        <td>{editUsers}</td>
                    </tr>
                    </tbody>
                </Table>
                <Button variant="outline-primary" href={"/partner/" + this.state.partnerId + "/users"}>Back to
                    list</Button>
                <Button variant="outline-primary"
                        href={"/partner/" + this.state.partnerId + "/user/" + this.state.id + "/edit"}>Edit</Button>
            </div>
        </Layout>;
    }
}

export default PartnerUserView
