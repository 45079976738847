import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {post, getHost} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";

const dayjs = require('dayjs');

const dateFormat = 'YYYY-MM-DD';

class InvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            errorMessage: "",
            filter: {
                Name: "",
                Company: "",
                InvoiceNumber: "",
                InvoiceDateStart: new Date(dayjs().subtract(3, 'months').format(dateFormat)),
                InvoiceDateEnd: new Date(),
                Paid: "",
                PaymentStatus: "",
            },
            filterHelper: {
                InvoiceDateStartEnabled: false,
                InvoiceDateEndEnabled: false,
            }
        };
        this.loadData = this.loadData.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.filterHelperChange = this.filterHelperChange.bind(this);
        this.invoiceDateStartChange = this.invoiceDateStartChange.bind(this);
        this.invoiceDateEndChange = this.invoiceDateEndChange.bind(this);
    }

    async loadData(filter) {
        const postData = {
            Filter: filter,
            FilterOptions: {}
        }
        const [res, err] = await post(this, "/invoice/search", postData);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({invoices: res.data.List});
    }

    async componentDidMount() {
        await this.loadData({});
    }

    onlyIfEnabled(filter, field, condition) {
        if (!condition) {
            delete filter[field]
        }
    }

    async handleSearch(event) {
        event.preventDefault();
        const filter = {...this.state.filter}; // we copy object because we edit the values

        this.yesNoBoolean(filter, "Paid");
        this.onlyIfEnabled(filter, "InvoiceDateStart", this.state.filterHelper.InvoiceDateStartEnabled)
        this.onlyIfEnabled(filter, "InvoiceDateEnd", this.state.filterHelper.InvoiceDateEndEnabled)

        await this.loadData(filter);
    }

    yesNoBoolean(filter, field) {
        if (!filter[field]) {
            delete filter[field];
            return
        }
        filter[field] = filter[field] === "Yes";
    }

    filterChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.filter;
        data[name] = value;

        this.setState({filter: data});
    }

    filterHelperChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.filterHelper;
        data[name] = value;

        this.setState({filterHelper: data});
    }

    invoiceDateStartChange(newDate) {
        const data = this.state.filter;
        data.InvoiceDateStart = newDate;
        this.setState({filter: data});
    }

    invoiceDateEndChange(newDate) {
        const data = this.state.filter;
        data.InvoiceDateEnd = newDate;
        this.setState({filter: data});
    }

    timeToDate(date) {
        const newDate = dayjs(date).format(dateFormat);
        return newDate;
    }

    render() {
        let totalSum = 0;
        let vatSum = 0;

        return <Layout activeMenu="invoice">
            <h1>Invoices ({this.state.invoices ? this.state.invoices.length : 0})</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            <Form className="form-fieldnames" onSubmit={e => this.handleSearch(e)}>
                <Row>
                    <Col sm>
                        <Form.Group controlId="formName">
                            <Form.Label column>Name:</Form.Label>
                            <Form.Control type="text" name="Name" defaultValue={this.state.filter.Name}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formCompany">
                            <Form.Label column>Company:</Form.Label>
                            <Form.Control type="text" name="Company" defaultValue={this.state.filter.Company}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formInvoiceNumber">
                            <Form.Label column>Invoice number:</Form.Label>
                            <Form.Control type="text" name="InvoiceNumber"
                                          defaultValue={this.state.filter.InvoiceNumber}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formInvoiceDateStart">{this.state.filterHelper.InvoiceDateStartEnabled}
                            <Form.Label column>Invoice date start:</Form.Label>
                            <Form.Check type="checkbox" name="InvoiceDateStartEnabled"
                                        defaultChecked={this.state.filterHelper.InvoiceDateStartEnabled}
                                        onChange={this.filterHelperChange}/>
                            {this.state.filterHelper.InvoiceDateStartEnabled &&
                            <DatePicker
                                selected={this.state.filter.InvoiceDateStart}
                                onChange={this.invoiceDateStartChange}
                                name="invoiceDatedStart"
                                dateFormat="yyyy.MM.dd"
                            />
                            }
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formInvoiceDateEnd">
                            <Form.Label column>Invoice date end:</Form.Label>
                            <Form.Check type="checkbox" name="InvoiceDateEndEnabled"
                                        defaultChecked={this.state.filterHelper.InvoiceDateEndEnabled}
                                        onChange={this.filterHelperChange}/>
                            {this.state.filterHelper.InvoiceDateEndEnabled &&
                            <DatePicker
                                selected={new Date(this.state.filter.InvoiceDateEnd)}
                                onChange={this.invoiceDateEndChange}
                                name="invoiceDateEnd"
                                dateFormat="yyyy.MM.dd"
                            />
                            }
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formPaid">
                            <Form.Label column>Paid:</Form.Label>
                            <Form.Control as="select" name="Paid" defaultValue={this.state.filter.Paid}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formPaymentStatus">
                            <Form.Label column>Payment:</Form.Label>
                            <Form.Control as="select" name="PaymentStatus"
                                          defaultValue={this.state.filter.PaymentStatus}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option value="unknown">Unknown</option>
                                <option value="cc_payment_success">CC / success</option>
                                <option value="cc_payment_fail">CC / failed</option>
                                <option value="manual_payment_paid">Manual / success</option>
                                <option value="manual_payment_not_paid">Manual / not paid</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Button type="submit" variant="primary">Search</Button>
                    </Col>
                </Row>
            </Form>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>InvoiceNumber</th>
                    <th>InvoiceDate</th>
                    <th>DueDate</th>
                    <th>Paid</th>
                    <th>Payment</th>
                    <th>Sum</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.state.invoices && this.state.invoices.map((value, index) => {
                    totalSum = totalSum + value.TotalSum;
                    vatSum = vatSum + value.VatSum;

                    let paid = <span className="badge badge-pill badge-warning">No</span>;
                    if (value.Paid === true) {
                        paid = <span className="badge badge-pill badge-success">Yes</span>;
                    }

                    let payment = <span className="badge badge-pill badge-danger">Unknown</span>;
                    if (value.PaymentStatus === "cc_payment_success") {
                        payment = <span className="badge badge-pill badge-success">CC / success</span>;
                    } else if (value.PaymentStatus === "cc_payment_fail") {
                        payment = <span className="badge badge-pill badge-warning">CC / failed</span>;
                    } else if (value.PaymentStatus === "manual_payment_paid") {
                        payment = <span className="badge badge-pill badge-success">Manual / success</span>;
                    } else if (value.PaymentStatus === "manual_payment_not_paid") {
                        payment = <span className="badge badge-pill badge-warning">Manual / not paid</span>;
                    }

                    return <tr key={index}>
                        <td>{value.SourceType}</td>
                        <td>{value.Name}</td>
                        <td>{value.Company}</td>
                        <td>{value.InvoiceNumber}</td>
                        <td>{this.timeToDate(value.InvoiceDate)}</td>
                        <td>{this.timeToDate(value.DueDate)}</td>
                        <td>{paid}</td>
                        <td>{payment}</td>
                        <td>{value.TotalSum} (incl VAT {value.VatSum})</td>
                        <td>
                            <Button variant="outline-primary" href={"/invoice/" + value.Id + "/view"}>View</Button>
                            <Button variant="outline-primary" href={getHost() + "/invoice/get/" + value.Id + "/pdf"}
                                    target="_tab">PDF</Button>
                        </td>
                    </tr>
                })}
                <tr>
                    <td className="text-right" colSpan="8">Sum with VAT</td>
                    <td colSpan="2"><b>{(vatSum + totalSum).toFixed(2)}</b></td>
                </tr>
                <tr>
                    <td className="text-right" colSpan="8">Sum without VAT</td>
                    <td colSpan="2"><b>{totalSum.toFixed(2)}</b></td>
                </tr>
                </tbody>
            </Table>
        </Layout>;
    }
}

export default InvoiceList
