import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";


class VoucherList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vouchers: [],
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/voucher");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({vouchers: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        return <Layout activeMenu="voucher">
            <h1>Vouchers</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Discount</th>
                    <th>StartTime</th>
                    <th>EndTime</th>
                    <th>Activations</th>
                    <th>&nbsp;</th>
                 </tr>
                </thead>
                <tbody>
                {this.state.vouchers.map((value, index) => {
                    return <tr key={index}>
                        <td>{value.Name}</td>
                        <td>{value.Code}</td>
                        <td>{value.DiscountPerc}%</td>
                        <td>{value.StartTime}</td>
                        <td>{value.EndTime}</td>
                        <td>
                            {!(value.NumActivated == value.NumAllowedActivations) && <span className="badge badge-pill badge-success">{value.NumActivated} / {value.NumAllowedActivations}</span>}
                            {(value.NumActivated == value.NumAllowedActivations) && <span className="badge badge-pill badge-warning">{value.NumActivated} / {value.NumAllowedActivations}</span>}
                        </td>
                        <td>
                            <Button variant="outline-primary" href={"/voucher/" + value.VoucherId + "/view"}>View</Button>
                            <Button variant="outline-primary" href={"/voucher/" + value.VoucherId + "/edit"}>Edit</Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
            <Button variant="primary" onClick={() => this.props.history.push('/voucher/new')}>Add voucher</Button>
        </Layout>;
    }
}

export default VoucherList