import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {get, post} from '../../utils/request'
import Layout from "../../component/Layout";
import Table from "react-bootstrap/Table";
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";
import {Spinner} from "react-bootstrap";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class ContainerNew extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            checked: 0,
            drives: [],
            data: {
                DriveCount: 3,
                ParityDriveCount: 1,
                FragmentSize: 2097152,
                DriveIds: []
            },
            location: location,
            loaded: false,
            successMessage: '',
            errorMessage: '',
        };

        this.loadDrives = this.loadDrives.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadDrives() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/drive/search/withnocontainer/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({drives: res.data.Drives});
        this.setState({errorMessage: ""});
        this.setState({loaded: true});
    }

    async componentDidMount() {
        await this.loadDrives();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            if (event.target.checked) {
                data[name] = [...data[name], event.target.value];
            } else {
                data[name].splice(data[name].indexOf(event.target.value), 1);
            }
        } else if (name === "DriveCount" || name === "ParityDriveCount" || name === "FragmentSize") {
            data[name] = parseInt(value, 10);
        } else {
            data[name] = value;
        }

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const [res, err] = await post(this, "/storage/container/in/" + this.state.location, this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Validation error: " + err.response.message});
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: err});
            return;
        } else if (res.data != null && res.data.InternalStatusCode != null && res.data.InternalStatusCode > 0) {
            switch (res.data.InternalStatusCode) {
                case 400:
                    this.setState({errorMessage: "Validation error: " + res.data.ErrorMessage});
                    return;
            }
            this.setState({errorMessage: res.data.ErrorMessage});
            return;
        }
        this.setState({successMessage: "Container created!"});
        await this.loadDrives();
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="container"/>
            <div style={divStyle}>
                <h1>Create container</h1>
                {!this.state.loaded &&
                    <>
                        <Spinner animation="border" variant="primary"/>Data not loaded...
                        <br/><br/>
                    </>
                }
                {this.state.loaded && <>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    <Table bordered hover>
                        <thead className="thead-light">
                        <tr>
                            <th>&nbsp;</th>
                            <th>NodeUuid</th>
                            <th>NodeAddress</th>
                            <th>Uuid</th>
                            <th>Online</th>
                            <th>Size</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.drives &&
                            this.state.drives.map((value, index) => {
                            return <tr key={index}>
                                <td><input type="checkbox" name="DriveIds" value={value.DriveId}
                                           onChange={this.handleChange}/></td>
                                <td>{value.NodeUuid}</td>
                                <td>{value.NodeAddress}</td>
                                <td>{value.Uuid}</td>
                                <td>
                                    {value.Online && <span className="badge badge-pill badge-success">Online</span>}
                                    {!value.Online && <span className="badge badge-pill badge-warning">Offline</span>}
                                </td>
                                <td>{value.Size} ({filesize(value.Size)})</td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                    {(!this.state.drives) &&
                        <Alert variant="danger">No available drives.</Alert>
                    }
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">DriveCount</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="DriveCount" defaultValue={this.state.data.DriveCount}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">ParityDriveCount</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="ParityDriveCount"
                                          defaultValue={this.state.data.ParityDriveCount} onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">FragmentSize</Form.Label>
                        <Col sm="6">
                            <Form.Control as="select" name="FragmentSize" defaultValue={this.state.data.FragmentSize}
                                          onChange={this.handleChange}>
                                <option value="2097152">2097152 (2MB)</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Save</Button>
                </Form>
                </>
                }
            </div>
        </Layout>;
    }
}

export default ContainerNew