import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {get} from '../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../component/Layout";


class AdminList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            admins: [],
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/admin/list");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({admins: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        return <Layout activeMenu="admin">
            <h1>Admins</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.state.admins.map((value, index) => {
                    return <tr key={index}>
                        <td>{value.Name}</td>
                        <td>{value.Email}</td>
                        <td>{value.User}</td>
                        <td>{value.Status}</td>
                        <td><Button variant="outline-primary" href={"/admin/" + value.Id}>Edit</Button></td>
                    </tr>
                })}
                </tbody>
            </Table>
            <Button variant="primary" onClick={() => this.props.history.push('/admin/new')}>Add admin</Button>
        </Layout>;
    }
}

export default AdminList