import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {get, post} from '../utils/request'
import Layout from "../component/Layout";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class AdminEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                User: '',
                Email: '',
                Pass: '',
                Name: '',
                Status: '',
            },
            successMessage: '',
            errorMessage: '',
        };

        this.loadData = this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/admin/get/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    async componentDidMount() {
        if (this.state.id !== "new") {
            await this.loadData();
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        let path = "/admin/edit/" + this.state.id;
        if (this.state.id === "new") {
            path = "/admin/create";
        }
        const [res, err] = await post(this, path, this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 409:
                        this.setState({errorMessage: "this admin already exists"});
                        return;
                    case 422:
                        this.setState({errorMessage: "validation error"});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({successMessage: "Admin updated!"});
        this.loadData();
    }

    render() {
        return <Layout activeMenu="admin">
            <div style={divStyle}>
                <h1>Admin edit</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                    <Form.Group as={Row} controlId="formPlaintextUser">
                        <Form.Label column sm="2">Username</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="user" defaultValue={this.state.data.User} onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="2">Name</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="name" defaultValue={this.state.data.Name} onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="2">Email</Form.Label>
                        <Col sm="10">
                            <Form.Control type="email" name="email" defaultValue={this.state.data.Email} onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="2">Status</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="status" defaultValue={this.state.data.Status} onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPassword">
                        <Form.Label column sm="2">Password</Form.Label>
                        <Col sm="10">
                            <Form.Control type="password" name="pass" onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Save</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default AdminEdit