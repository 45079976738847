import React from "react";
import {Nav} from "react-bootstrap";
import {get} from "../utils/request";

export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            activeMenu: props.activeMenu
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/admin/permissions");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({items: res.data.Menu});
    }

    render() {
        return <Nav variant="tabs" className="justify-content-center" activeKey={"/" + this.state.activeMenu}>
            <Nav.Link href="/">Home</Nav.Link>
            {this.state.items.map((value, index) => {
                return <Nav.Link key={index} href={value.Url}>{value.Title}</Nav.Link>
            })}
            <Nav.Link href="/signOut">Sign out</Nav.Link>
        </Nav>;
    }
}
