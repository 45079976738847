import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {get, post} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import InvoiceNav from "../../component/InvoiceNav";
import dayjs from "dayjs";
import {Spinner} from "react-bootstrap";

const dateFormat = 'YYYY-MM-DD HH:mm';

class InvoicePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                SourceType: "",
                SourceId: ""
            },
            loaded: false,
            errorMessage: "",
            payments: [],
        };
        this.loadInvoice = this.loadInvoice.bind(this);
        this.loadPayments = this.loadPayments.bind(this);
    }

    async loadPayments() {
        const request = {
            Filter: {
                InvoiceId: this.state.id,
            },
            FilterOptions: {
                Desc: true,
            },
        }

        const [res, err] = await post(this, "/payment/search", request);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({payments: res.data.Payments});
    }

    async componentDidMount() {
        await this.loadInvoice();
        await this.loadPayments();
        this.setState({loaded: true});
    }

    async loadInvoice() {
        const [res, err] = await get(this, "/invoice/get/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    timeToDate(date) {
        return dayjs(date).format(dateFormat);
    }

    render() {
        return <Layout activeMenu="payment">
            <InvoiceNav activeKey="payment" id={this.state.id}/>
            <h1>Payment</h1>
            <br/>
            {this.state.errorMessage && <>
                <Alert variant="danger">{this.state.errorMessage}</Alert>
                <br/>
            </>}
            {!this.state.loaded &&
                <>
                    <Spinner animation="border" variant="primary"/> Data not loaded...
                    <br/><br/>
                </>
            }
            {this.state.loaded && <>
                <Button variant="primary"
                        href={"/invoice/" + this.state.id + "/paymentMake"}>Make
                    a new payment</Button>
                <br/><br/>
                <h2>Payments done</h2>
                <Table bordered hover>
                    <thead className="thead-light">
                    <tr>
                        <th>Id</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Method</th>
                        <th>Method ID</th>
                        <th>Invoices</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.payments && this.state.payments.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{this.timeToDate(item.Created)}</td>
                            <td>{item.Status}</td>
                            <td>{item.Amount}</td>
                            <td>{item.Method}</td>
                            <td>{item.MethodId}</td>
                            <td>{item.InvoiceIds && item.InvoiceIds.map((item) => {
                                return <>
                                    <a href={"/invoice/" + item + "/view/"}
                                       target="_blank">{item}<>&#8599;</>
                                    </a><br/>
                                </>
                            })}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </>}
        </Layout>;
    }
}

export default InvoicePayment