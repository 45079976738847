import React from 'react';
import Tab from 'react-bootstrap/Tab';
import {Nav} from "react-bootstrap";
import {get} from "../utils/request";

class LocationSelector extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const selectedLocation = query.get('location');

        this.state = {
            data: [],
            errorMessage: "",
            selectedLocation: selectedLocation
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/location");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    redirectIfNeeded() {
        if (!this.state.selectedLocation) {
            const newLocation = this.state.data[0].uid;
            window.location.replace(window.location + "?location=" + newLocation);
        }
    }

    async componentDidMount() {
        await this.loadData();
        this.redirectIfNeeded();
    }
    render() {
        return (
            <Tab.Container>
                <Nav variant="tabs" className="justify-content-left">
                    {
                        this.state.data.map((option,i)=>{
                            return <Nav.Item>
                                <Nav.Link
                                    active={this.state.selectedLocation === option.uid}
                                    href={"?location="+ option.uid}
                                    eventKey={option.locationId}
                                    value={option.uid}
                                >
                                    {option.uid}
                                </Nav.Link>
                            </Nav.Item>
                        })
                    }
                </Nav>
            </Tab.Container>
        );

    }
}
export default LocationSelector