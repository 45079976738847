import React from 'react'
import {get} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert, Spinner} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import StorageNav from "../../component/StorageNav";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};
class DriveView extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            data: {},
            errorMessage: "",
            id: this.props.match.params.id,
            loaded: false,
            location: location
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/drive/" + this.state.id + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({data: res.data});
        this.setState({errorMessage: ""});
        this.setState({loaded: true});
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="drive"/>
            <div style={divStyle}>
                {this.state.errorMessage &&
                <Alert>
                    {this.state.errorMessage}
                </Alert>
                }
                <h1>Drive {this.state.data.Uuid}</h1>
                {!this.state.loaded &&
                    <>
                        <Spinner animation="border" variant="primary"/>Data not loaded...
                        <br/><br/>
                    </>
                }
                {this.state.loaded && <>
                <Table responsive="sm" bordered className="drive-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>DriveId</td>
                        <td>{this.state.data.DriveId}</td>
                    </tr>
                    <tr>
                        <td>Online</td>
                        <td>
                            {this.state.data.Online && <span className="badge badge-pill badge-success">Yes</span>}
                            {!this.state.data.Online && <span className="badge badge-pill badge-warning">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>NodeId</td>
                        <td>
                            {this.state.data.NodeId === "000000000000000000000000" &&
                            <i>none</i>
                            }
                            {this.state.data.NodeId !== "000000000000000000000000" &&
                            <a href={"/storage/node/" + this.state.data.NodeId  + "/view?location=" + this.state.location}>{this.state.data.NodeId}</a>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>NodeAddress</td>
                        <td>{this.state.data.NodeAddress}</td>
                    </tr>
                    <tr>
                        <td>Size</td>
                        <td>{this.state.data.Size}</td>
                    </tr>
                    <tr>
                        <td>Uuid</td>
                        <td>{this.state.data.Uuid}</td>
                    </tr>
                    <tr>
                        <td>ContainerId</td>
                        <td>
                            {this.state.data.ContainerId === "000000000000000000000000" &&
                            <i>none</i>
                            }
                            {this.state.data.ContainerId !== "000000000000000000000000" &&
                            <a href={"/storage/container/" + this.state.data.ContainerId + "/view?location=" + this.state.location}>{this.state.data.ContainerId}</a>
                            }
                        </td>
                    </tr>
                    </tbody>
                </Table>
                </>
                }
            </div>
        </Layout>;
    }
}

export default DriveView
