import React from 'react'
import Button from 'react-bootstrap/Button';
import {get} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert, Spinner} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

class ContainerView extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            data: {},
            errorMessage: "",
            id: this.props.match.params.id,
            location: location,
            loaded: false,
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/container/" + this.state.id + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({data: res.data});
        this.setState({errorMessage: ""});
        this.setState({loaded: true});
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="container" />
            <div style={divStyle}>
                {this.state.errorMessage &&
                <Alert>
                    {this.state.errorMessage}
                </Alert>
                }
                <h1>Container {this.state.data.ContainerId}</h1>
                {!this.state.loaded &&
                    <>
                        <Spinner animation="border" variant="primary"/>Data not loaded...
                        <br/><br/>
                    </>
                }
                {this.state.loaded && <>
                <Table responsive="sm" bordered className="container-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>WriteActive</td>
                        <td>
                            {this.state.data.WriteActive && <span className="badge badge-pill badge-success">Yes</span>}
                            {!this.state.data.WriteActive && <span className="badge badge-pill badge-warning">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>KeepEmpty</td>
                        <td>
                            {this.state.data.KeepEmpty && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!this.state.data.KeepEmpty && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>PauseDeletes</td>
                        <td>
                            {this.state.data.PauseDeletes && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!this.state.data.PauseDeletes && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>Deleted</td>
                        <td>
                            {this.state.data.IsDeleted && <span className="badge badge-pill badge-danger">Yes</span>}
                            {!this.state.data.IsDeleted && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>ActiveVersion</td>
                        <td><span className="badge badge-pill badge-secondary">{this.state.data.ActiveVersion}</span></td>
                    </tr>
                    <tr>
                        <td>DriveCount</td>
                        <td>{this.state.data.DriveCount}</td>
                    </tr>
                    <tr>
                        <td>DataDriveCount</td>
                        <td>{this.state.data.DataDriveCount}</td>
                    </tr>
                    <tr>
                        <td>ParityDriveCount</td>
                        <td>{this.state.data.ParityDriveCount}</td>
                    </tr>
                    <tr>
                        <td>DriveSize</td>
                        <td>{this.state.data.DriveSize}</td>
                    </tr>
                    <tr>
                        <td>Size</td>
                        <td>{this.state.data.Size}</td>
                    </tr>
                    <tr>
                        <td>Free</td>
                        <td>{this.state.data.Free}</td>
                    </tr>
                    <tr>
                        <td>FragmentSize</td>
                        <td>{this.state.data.FragmentSize}</td>
                    </tr>
                    <tr>
                        <td>SegmentSize</td>
                        <td>{this.state.data.SegmentSize}</td>
                    </tr>
                    <tr>
                        <td>RefreshNeeded</td>
                        <td>
                            {this.state.data.RefreshNeeded && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!this.state.data.RefreshNeeded && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>ReconstructNeeded</td>
                        <td>
                            {this.state.data.ReconstructNeeded && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!this.state.data.ReconstructNeeded && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                    </tr>
                    </tbody>
                </Table>

                <h2>Container versions</h2>
                <Table bordered hover>
                    <thead className="thead-light">
                    <tr>
                        <th>Version</th>
                        <th>ReadActive</th>
                        <th>WriteActive</th>
                        <th>Usage</th>
                        <th>Drives</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.data.Versions && this.state.data.Versions.map((item, index) => {
                        return <tr>
                            <td><span className="badge badge-pill badge-secondary">{item.Version}</span></td>
                            <td>
                                {item.ReadActive && <span className="badge badge-pill badge-success">Yes</span>}
                                {!item.ReadActive && <span className="badge badge-pill badge-warning">No</span>}
                            </td>
                            <td>
                                {item.WriteActive && <span className="badge badge-pill badge-success">Yes</span>}
                                {!item.WriteActive && <span className="badge badge-pill badge-warning">No</span>}
                            </td>
                            <td>{filesize(item.Usage)}</td>
                            <td>
                                <table>
                                    <thead className="thead-light">
                                    <tr>
                                        <th>DriveId</th>
                                        <th>DriveUuid</th>
                                        <th>NodeAddress</th>
                                        <th>DriveOnline</th>
                                    </tr>
                                    </thead>
                                    <tbody>{item.Drives && item.Drives.map((drive, index2) => {
                                    return <tr>
                                        <td><a href={"/storage/drive/" + drive.DriveId  + "/view?location=" + this.state.location}>{drive.DriveId}</a></td>
                                        <td>{drive.DriveUuid}</td>
                                        <td>{drive.NodeAddress}</td>
                                        <td>
                                            {drive.DriveOnline && <span className="badge badge-pill badge-success">Yes</span>}
                                            {!drive.DriveOnline && <span className="badge badge-pill badge-warning">No</span>}
                                        </td>
                                    </tr>})}
                                </tbody>
                                </table>
                            </td>
                            </tr>})}
                    </tbody>
                </Table>
                <Button variant="outline-primary" href={"/storage/container/" + this.state.id + "/edit?location=" + this.state.location}>Edit</Button>
                </>
                }
            </div>
          </Layout>;
    }
}

export default ContainerView
