import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {get, post, put} from '../../utils/request'
import Layout from "../../component/Layout";
import CountrySelector from "../../component/CountrySelector";
import PartnerNav from "../../component/PartnerNav";
import Select from "react-select";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

const statuses = [
    {label: "", value: ""},
    {label: "Created", value: "created"},
    {label: "Waiting Verification", value: "waiting_verification"},
    {label: "Active", value: "active"},
    {label: "Closed", value: "closed"}
];
class PartnerNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                name: '',
                email: '',
                emailForBilling: '',
                countryCode: '',
                address: '',
                registrationNumber: '',
                vatNumber: '',
                status: '',
                discountPerc: 5,
                invoiceComment: '',
            },
            successMessage: '',
            errorMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    capitalize(string) {
        return string ? string[0].toUpperCase() + string.slice(1) : "";
    }
    handleCountryCodeChange(newValue) {
        const data = this.state.data;
        data.CountryCode = newValue;
        this.setState({data: data});
    }
    handleStatusChange(event) {
        const data = this.state.data;
        data.status = event.value;
        this.setState({data: data});
    }
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            data[name] = event.target.checked;
        } else if (name === "discountPerc") {
            data[name] = parseInt(value, 10);
        } else {
            data[name] = value;
        }

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});
        this.setState({successMessage: ""});

        const [res, err] = await post(this, "/partner", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request: " + err.response.message});
                        return;
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 422:
                        if (err.response.data !== null) {
                            this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        } else {
                            this.setState({errorMessage: "Validation error"});
                        }
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: err});
            return;
        }
        this.props.history.push('/partner/' + res.data.partnerId + '/view')
    }

    render() {
        return <Layout activeMenu="partner">
            <PartnerNav activeKey="partner" id={this.state.id}/>
            <div style={divStyle}>
                <h1>Create a new partner</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="6">Name</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="name" defaultValue={this.state.data.name}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="6">Email</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="email" defaultValue={this.state.data.email}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="6">Email for Billing</Form.Label>
                        <Col sm="6">
                            <Form.Control type="email" name="emailForBilling"
                                          defaultValue={this.state.data.emailForBilling}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formCountryCode">
                        <Form.Label column sm="6">Country</Form.Label>
                        <Col sm="6">
                            <CountrySelector
                                selected={this.state.data.countryCode}
                                onChange={this.handleCountryCodeChange.bind(this)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextAddress">
                        <Form.Label column sm="6">Address</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="address" defaultValue={this.state.data.address}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextRegistrationNumber">
                        <Form.Label column sm="6">Registration number</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="registrationNumber"
                                          defaultValue={this.state.data.registrationNumber}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextVatNumber">
                        <Form.Label column sm="6">VAT code</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="vatNumber" defaultValue={this.state.data.vatNumber}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="6">Status</Form.Label>
                        <Col sm="6">
                            <Select
                                name="status"
                                options={statuses}
                                defaultValue={{
                                    value: this.state.data.status,
                                    label: this.capitalize(this.state.data.status)
                                }}
                                onChange={this.handleStatusChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextDiscountPerc">
                        <Form.Label column sm="6">Discount %</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="discountPerc" defaultValue={this.state.data.discountPerc}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextInvoiceComment">
                        <Form.Label column sm="6">Invoice comment</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="invoiceComment" as="textArea"
                                          onChange={this.handleChange}>{this.state.data.invoiceComment}</Form.Control>
                        </Col>
                    </Form.Group>

                    <Button type="submit" variant="primary">Save</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default PartnerNew