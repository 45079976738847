import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import LocationSelector from "../../component/LocationSelector";
import {Spinner} from "react-bootstrap";
class NodeList extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            errorMessage: "",
            loaded: false,
            location: location,
            nodes: [],
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }
    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/node/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({loaded: true});
        this.setState({errorMessage: ""});
        this.setState({nodes: res.data.Nodes});
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="node" />
            <h1>Nodes</h1>
            <LocationSelector></LocationSelector>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {!this.state.loaded &&
                <>
                    <Spinner animation="border" variant="primary"/> Data not loaded...
                    <br/><br/>
                </>
            }
            {this.state.loaded && <>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>NodeId</th>
                    <th>StorageDrive</th>
                    <th>Uuid</th>
                    <th>Address</th>
                    <th>WorkerPriority</th>
                    <th>WorkerHeartBeat</th>
                </tr>
                </thead>
                <tbody>
                {this.state.nodes.map((value, index) => {
                    return <tr key={index}>
                        <td><a href={"/storage/node/" + value.NodeId  + "/view?location=" + this.state.location}>{value.NodeId}</a></td>
                        <td>
                            {value.Services.StorageDrive.Online && <span className="badge badge-pill badge-success">Online</span>}
                            {!value.Services.StorageDrive.Online &&  <span className="badge badge-pill badge-warning">Offline</span>}
                        </td>
                        <td>{value.Uuid}</td>
                        <td>{value.Address}</td>
                        <td>{value.WorkerPriority}</td>
                        <td>{value.WorkerHeartBeat}</td>
                    </tr>
                })}
                </tbody>
            </Table>
            </>
            }
        </Layout>;
    }
}

export default NodeList