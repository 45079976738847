import React from 'react'
import Tab from 'react-bootstrap/Tab'
import {Nav} from "react-bootstrap";


function StorageNav({activeKey}) {
    return (
        <Tab.Container >
            <Nav variant="tabs" className="justify-content-center" activeKey={activeKey}>
                <Nav.Item>
                    <Nav.Link active={activeKey === "overview"} eventKey="overview" href={"/storage"} >Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "node"} eventKey="node" href={"/storage/node"} >Nodes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "drive"} eventKey="drive" href={"/storage/drive"}>Drives</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "container"} eventKey="container" href={"/storage/container"}>Containers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "task"} eventKey="task" href={"/storage/task"}>Tasks</Nav.Link>
                </Nav.Item>
            </Nav>
        </Tab.Container>
    );
}
export default StorageNav

