import axios from "axios";

const axiosConfig = {withCredentials: true};

export async function get(app, path) {
    try {
        const res = await axios.get(getHost() + path, axiosConfig);
        return [res, null];
    } catch (e) {
        unauthorizedRedirect(app, e);
        return [null, e];
    }
}

export async function post(app, path, data) {
    let res
    try {
        res = await axios.post(getHost() + path, data, axiosConfig);
        return [res, null];
    } catch (e) {
        unauthorizedRedirect(app, e);
        return [res, e];
    }
}

export async function put(app, path, data) {
    let res
    try {
        res = await axios.put(getHost() + path, data, axiosConfig);
        return [res, null];
    } catch (e) {
        unauthorizedRedirect(app, e);
        return [res, e];
    }
}

export async function del(app, path) {
    try {
        const res = await axios.delete(getHost() + path, axiosConfig);
        return [res, null];
    } catch (e) {
        unauthorizedRedirect(app, e);
        return [null, e];
    }
}

function unauthorizedRedirect(app, e) {
    if (e.response && e.response.status === 401) {
        app.props.history.push('/signIn');
    }
}

export function getHost() {
    return process.env.REACT_APP_BACKEND;
}
