import React from 'react'
import Tab from 'react-bootstrap/Tab'
import {Nav} from "react-bootstrap";


function AccountNav({activeKey, id}) {
    return (
       <Tab.Container >
            <Nav variant="tabs" className="justify-content-center" activeKey={activeKey}>
                <Nav.Item>
                    <Nav.Link active={activeKey === "edit"} eventKey="edit" href={"/account/" + id} >Edit</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "usage"} eventKey="usage" href={"/account/"+ id + "/usage"}>Usage</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "package"} eventKey="package" href={"/account/"+ id + "/package"}>Package</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "statistics"} eventKey="statistics" href={"/account/"+ id + "/statistics"}>Statistics</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "partner"} eventKey="partner" href={"/account/"+ id + "/partner"}>Partner</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "partnerSettings"} eventKey="partnerSettings" href={"/account/"+ id + "/partnerSettings"}>Partner Settings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "closure"} eventKey="closure" href={"/account/"+ id + "/closure"}>Closure</Nav.Link>
                </Nav.Item>
            </Nav>
            <Tab.Content >
               <Tab.Pane eventKey="edit">
               </Tab.Pane>
                <Tab.Pane eventKey="usage">
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}
export default AccountNav

