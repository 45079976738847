import React from 'react'
import Button from 'react-bootstrap/Button';

import {get, getHost, post} from '../../utils/request'
import Layout from "../../component/Layout";
import {Alert} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import {CountryByCode} from "../../component/CountryByCode";
import dayjs from "dayjs";
import InvoiceNav from "../../component/InvoiceNav";

const divStyle = {
    margin: '40px auto',
    width: '700px'
};

const dateFormat = 'YYYY-MM-DD HH:mm';

class InvoiceView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            payments: [],
            data: {
                InvoiceId: '',
                InvoiceKey: '',
                SourceType: '',
                SourceId: '',
                Created: '',
                InvoiceNumber: '',
                InvoiceDate: '',
                DueDate: '',
                Name: '',
                Address: '',
                CountryCode: '',
                VatCode: '',
                VatRate: '',
                PriceSum: '',
                VatSum: '',
                TotalSum: '',
                InvoiceComment: '',
                DiscountPerc: '',
                InvoiceLines: [],
            },
            errorMessage: '',
        };
        this.loadData = this.loadData.bind(this);
        this.loadPayments = this.loadPayments.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
        await this.loadPayments();
    }

    async loadPayments() {
        const request = {
            Filter: {
                AccountId: this.state.data.AccountId,
                InvoiceId: this.state.data.InvoiceId
            },
            FilterOptions: {
                Desc: true,
            },
        }
        const [res, err] = await post(this, "/payment/search", request);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({payments: res.data.Payments});
    }

    async loadData() {
        const [res, err] = await get(this, "/invoice/get/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({data: res.data});
    }

    timeToDate(date) {
        const newDate = dayjs(date).format(dateFormat);
        return newDate;
    }

    render() {
        let paid = <span className="badge badge-pill badge-warning">No</span>;
        if (this.state.data.Paid === true) {
            paid = <span className="badge badge-pill badge-success">Yes</span>;
        }

        return <Layout activeMenu="invoice">
            <InvoiceNav activeKey="invoice" id={this.state.id}/>
            <div style={divStyle}>
                {this.state.errorMessage &&
                <Alert>
                    {this.state.errorMessage}
                </Alert>
                }
                <h1>Invoice No. {this.state.data.InvoiceNumber}</h1>
                <a href={"/eventLog/?keyInvoiceId=" + this.state.id} target="_blank">Show related events <>&#8599;</>
                </a><br/>
                <Table responsive="sm" bordered className="invoice-table">
                    <thead className="thead-light"></thead>
                    <tbody>
                    <tr>
                        <td>Invoice Id</td>
                        <td>{this.state.data.InvoiceId}</td>
                    </tr>
                    <tr>
                        <td>Paid</td>
                        <td>{paid}</td>
                    </tr>
                    <tr>
                        <td>Invoice Key</td>
                        <td>{this.state.data.InvoiceKey}</td>
                    </tr>
                    <tr>
                        <td>Source</td>
                        <td>
                            {this.state.data.SourceType === "account" && <span>Account <a
                                href={"/" + this.state.data.SourceType + "/" + this.state.data.SourceId}
                                target="_tab">{this.state.data.SourceId}</a></span>
                            }
                            {this.state.data.SourceType === "partner" && <span>Partner <a
                                href={"/" + this.state.data.SourceType + "/" + this.state.data.SourceId + "/view"}
                                target="_tab">{this.state.data.SourceId}</a></span>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td>{this.state.data.Created}</td>
                    </tr>
                    <tr>
                        <td>Invoice Number</td>
                        <td>{this.state.data.InvoiceNumber}</td>
                    </tr>
                    <tr>
                        <td>Invoice Date</td>
                        <td>{this.state.data.InvoiceDate}</td>
                    </tr>
                    <tr>
                        <td>Due Date</td>
                        <td>{this.state.data.DueDate}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{this.state.data.Name}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{this.state.data.Address}</td>
                    </tr>
                    <tr>
                        <td>Company</td>
                        <td>{this.state.data.Company}</td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>{this.state.data.CountryCode} / {CountryByCode(this.state.data.CountryCode)}</td>
                    </tr>
                    <tr>
                        <td>Discount</td>
                        <td>{this.state.data.DiscountPerc}%</td>
                    </tr>
                    <tr>
                        <td>Invoice comment</td>
                        <td>{this.state.data.InvoiceComment}</td>
                    </tr>
                    <tr>
                        <td>Vat Code</td>
                        <td>{this.state.data.VatCode}</td>
                    </tr>
                    <tr>
                        <td>Vat Rate</td>
                        <td>{this.state.data.VatRate}%</td>
                    </tr>
                    <tr>
                        <td className="padding-zero" colSpan="2">
                            <Table responsive="sm" className="bordered hover">
                                <thead className="thead-light">
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Unit name</th>
                                    <th>Original price</th>
                                    <th>Discount</th>
                                    <th>Price</th>
                                    <th>Vat Sum</th>
                                    <th>Price Sum</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.InvoiceLines && this.state.data.InvoiceLines.map((value, index) => {
                                    return <tr>
                                        <td>{value.Code}</td>
                                        <td>{value.Description}</td>
                                        <td>{value.Quantity}</td>
                                        <td>{value.UnitName}</td>
                                        <td>{value.OriginalPrice}</td>
                                        <td>{value.DiscountPerc}%</td>
                                        <td>{value.Price}</td>
                                        <td>{value.VatSum}</td>
                                        <td>{value.PriceSum}</td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan="8">Price Sum</td>
                                    <td colSpan="1"><b>{this.state.data.PriceSum}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan="8">Vat Sum</td>
                                    <td colSpan="1"><b>{this.state.data.VatSum}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan="8">Total Sum</td>
                                    <td colSpan="1"><b>{this.state.data.TotalSum}</b></td>
                                </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <h2>Payments done</h2>
                <Table bordered hover>
                    <thead className="thead-light">
                    <tr>
                        <th>Id</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Method</th>
                        <th>Method ID</th>
                        <th>Invoices</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.payments && this.state.payments.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{this.timeToDate(item.Created)}</td>
                            <td>{item.Status}</td>
                            <td>{item.Amount}</td>
                            <td>{item.Method}</td>
                            <td>{item.MethodId}</td>
                            <td>{item.InvoiceIds && item.InvoiceIds.map((item) => {
                                return <>
                                    <a href={"/invoice/" + item + "/view/"}
                                       target="_blank">{item}<>&#8599;</>
                                    </a><br/>
                                </>
                            })}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                <Button type="submit" variant="primary"
                        href={getHost() + "/invoice/get/" + this.state.data.InvoiceId + "/pdf"}
                        target="_tab">PDF</Button>
            </div>
        </Layout>;
    }
}

export default InvoiceView
