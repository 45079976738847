import React from 'react'

import {get} from '../utils/request'
import Layout from "../component/Layout";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/overview");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        // it does not return anything at the moment
        //this.setState({data: res.data});
    }

    async componentDidMount() {
        this.loadData();
    }

    render() {

        return <Layout activeMenu="">
            <div style={divStyle}>
                <h1>Admin home</h1>
            </div>
        </Layout>;
    }
}

export default Overview