import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {get, post, put} from '../../utils/request'
import Layout from "../../component/Layout";
import DatePicker from "react-datepicker";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class VoucherEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                Code: '',
                Name: '',
                DiscountPerc: '',
                StartDate: '',
                EndDate: '',
                AccountPaidStartFrom: '',
                NumAllowedActivations: '',
            },
            successMessage: '',
            errorMessage: '',
        };

        this.loadData = this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/voucher/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        let dataWithDates = res.data
        dataWithDates.StartDate = dataWithDates.StartTime
        dataWithDates.EndDate = dataWithDates.EndTime
        this.setState({data: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            data[name] = event.target.checked;
        } else if (name === "DiscountPerc" || name === "NumAllowedActivations") {
            data[name] = parseInt(value, 10);
        } else {
            data[name] = value;
        }

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const [res, err] = await put(this, "/voucher/" + this.state.id, this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request: " + err.response.message});
                        return;
                    case 409:
                        this.setState({errorMessage: "Conflict, voucher with such code already exists"});
                        return;
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 422:
                        if (err.response.data !== null) {
                            this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        } else {
                            this.setState({errorMessage: "Validation error"});
                        }
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: err});
            return;
        }
        this.props.history.push('/voucher/' + this.state.id + '/view')
    }

    render() {
        return <Layout activeMenu="voucher">
            <div style={divStyle}>
                <h1>Voucher edit</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

                    <Form.Group as={Row} controlId="formPlaintextCode">
                        <Form.Label column sm="6">Code</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="Code" defaultValue={this.state.data.Code}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="6">Name</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="Name" defaultValue={this.state.data.Name}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextDiscountPerc">
                        <Form.Label column sm="6">DiscountPerc</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="DiscountPerc" defaultValue={this.state.data.DiscountPerc}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextStartDate">
                        <Form.Label column sm="6">StartDate</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="StartDate" defaultValue={this.state.data.StartDate}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEndDate">
                        <Form.Label column sm="6">EndDate</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="EndDate" defaultValue={this.state.data.EndDate}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextAccountPaidStartFrom">
                        <Form.Label column sm="6">Account PaidStart From</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="AccountPaidStartFrom"
                                          defaultValue={this.state.data.AccountPaidStartFrom}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextNumAllowedActivations">
                        <Form.Label column sm="6">Num Allowed Activations</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="NumAllowedActivations"
                                          defaultValue={this.state.data.NumAllowedActivations}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Button type="submit" variant="primary">Save</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default VoucherEdit