import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";
import LocationSelector from "../../component/LocationSelector";
import {Spinner} from "react-bootstrap";

class ContainerList extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            location: location,
            containers: [],
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }
    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/container/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({loaded: true});
        this.setState({errorMessage: ""});
        this.setState({containers: res.data.Containers});
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="container" />
            <h1>Containers</h1>
            <LocationSelector></LocationSelector>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {!this.state.loaded &&
                <>
                    <Spinner animation="border" variant="primary"/> Data not loaded...
                    <br/><br/>
                </>
            }
            {this.state.loaded && <>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>ContainerId</th>
                    <th>Write Active</th>
                    <th>Keep Empty</th>
                    <th>Pause Deletes</th>
                    <th>Active Version</th>
                    <th>Drive Count</th>
                    <th>Data Drive Count</th>
                    <th>Parity Drive Count</th>
                    <th>Drive Size</th>
                    <th>Size</th>
                    <th>Free</th>
                    <th>Fragment Size</th>
                    <th>Segment Size</th>
                    <th>Refresh Needed</th>
                    <th>Reconstruct Needed</th>
                </tr>
                </thead>
                <tbody>
                {this.state.containers && this.state.containers.map((value, index) => {
                    return <tr key={index}>
                        <td><a href={"/storage/container/" + value.ContainerId + "/view?location=" + this.state.location}>{value.ContainerId}</a></td>
                        <td>
                            {value.WriteActive && <span className="badge badge-pill badge-success">Yes</span>}
                            {!value.WriteActive && <span className="badge badge-pill badge-warning">No</span>}
                        </td>
                        <td>
                            {value.KeepEmpty && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!value.KeepEmpty && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                        <td>
                            {value.PauseDeletes && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!value.PauseDeletes && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                        <td>{value.ActiveVersion}</td>
                        <td>{value.DriveCount}</td>
                        <td>{value.DataDriveCount}</td>
                        <td>{value.ParityDriveCount}</td>
                        <td>{filesize(value.DriveSize)}</td>
                        <td>{filesize(value.Size)}</td>
                        <td>{filesize(value.Free)}</td>
                        <td>{filesize(value.FragmentSize)}</td>
                        <td>{filesize(value.SegmentSize)}</td>
                        <td>
                            {value.RefreshNeeded && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!value.RefreshNeeded && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                        <td>
                            {value.ReconstructNeeded && <span className="badge badge-pill badge-warning">Yes</span>}
                            {!value.ReconstructNeeded && <span className="badge badge-pill badge-success">No</span>}
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
            <Button variant="primary" onClick={() => this.props.history.push('/storage/container/new?location=' + this.state.location)}>Add container</Button>
            </>
            }
        </Layout>;
    }
}

export default ContainerList