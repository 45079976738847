import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";
import LocationSelector from "../../component/LocationSelector";
import {Spinner} from "react-bootstrap";

class DriveList extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            drives: [],
            errorMessage: "",
            loaded: false,
            locations: [],
            location: location,
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/drive/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({loaded: true});
        this.setState({errorMessage: ""});
        this.setState({drives: res.data.Drives});
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="drive" />
            <h1>Drives</h1>
            <LocationSelector></LocationSelector>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {!this.state.loaded &&
                <>
                    <Spinner animation="border" variant="primary"/> Data not loaded...
                    <br/><br/>
                </>
            }
            {this.state.loaded && <>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>DriveId</th>
                    <th>Online</th>
                    <th>NodeId</th>
                    <th>NodeAddress</th>
                    <th>Size</th>
                    <th>Uuid</th>
                    <th>ContainerId</th>
                </tr>
                </thead>
                <tbody>
                {this.state.drives.map((drive, index) => {
                    return <tr key={index}>
                        <td>
                            <i className="bi bi-hdd"></i>
                            <a href={"/storage/drive/" + drive.DriveId  + "/view?location=" + this.state.location}>{drive.DriveId}</a>
                        </td>
                        <td>
                            {drive.Online && <span className="badge badge-pill badge-success">Online</span>}
                            {!drive.Online && <span className="badge badge-pill badge-warning">Offline</span>}
                        </td>
                        <td>
                            {drive.NodeId  === "000000000000000000000000" &&
                            <i>none</i>
                            }
                            {drive.NodeId !== "000000000000000000000000" &&
                            <a href={"/storage/node/" + drive.NodeId + "/view?location=" + this.state.location}>{drive.NodeId}</a>
                            }
                        </td>
                        <td>{drive.NodeAddress}</td>
                        <td>{filesize(drive.Size)}</td>
                        <td>{drive.Uuid}</td>
                        <td>
                            {drive.ContainerId  === "000000000000000000000000" &&
                            <i>none</i>
                            }
                            {drive.ContainerId !== "000000000000000000000000" &&
                            <a href={"/storage/container/" + drive.ContainerId + "/view?location=" + this.state.location}>{drive.ContainerId}</a>
                            }
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
            </>
            }
        </Layout>;
    }
}

export default DriveList