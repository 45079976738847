import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {get} from '../../utils/request';
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
class LocationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/location");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({locations: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        const { locations, errorMessage  } = this.state;

        return <Layout activeMenu="location">
            <h1>Locations</h1>
            <br/>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Nr.</th>
                    <th>Status</th>
                    <th>UID</th>
                    <th>Domain</th>
                    <th>DNS names</th>
                    <th>Valid</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {locations.map((value, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.status}</td>
                        <td>{value.uid}</td>
                        <td>{value.domain}</td>
                        <td>{value.certDnsNames}</td>
                        <td>{value.certValidNotBefore} - {value.certValidNotAfter}</td>
                        <td>
                            <Button variant="outline-primary" href={"/location/" + value.locationId + "/view"}>View</Button>
                            <Button variant="outline-primary" href={"/location/" + value.locationId + "/edit"}>Edit</Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Layout>;
    }
}

export default LocationList