import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {get, post, put} from '../../utils/request'
import Layout from "../../component/Layout";
import PartnerNav from "../../component/PartnerNav";
import Select from "react-select";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

const statuses = [
    {label: "", value: ""},
    {label: "Waiting Confirmation", value: "waiting_confirm"},
    {label: "Active", value: "active"},
    {label: "Disabled", value: "disabled"}
];

class PartnerUserNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerId: this.props.match.params.partnerId,
            data: {
                name: '',
                email: '',
                status: '',
                pass: '',
                rolesEditUsers: true,
            },
            partnerName: '',
            successMessage: '',
            errorMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadPartner = this.loadPartner.bind(this);
    }

    async loadPartner() {
        const [res, err] = await get(this, "/partner/" + this.state.partnerId);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({partnerName: res.data.name});
    }

    async componentDidMount() {
        await this.loadPartner();
    }

    capitalize(string) {
        return string ? string[0].toUpperCase() + string.slice(1) : "";
    }

    handleStatusChange(event) {
        const data = this.state.data;
        data.status = event.value;
        this.setState({data: data});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            data[name] = event.target.checked;
        } else {
            data[name] = value;
        }

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const [res, err] = await post(this, "/partner/" + this.state.partnerId + "/user", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request: " + err.response.message});
                        return;
                    case 409:
                        this.setState({errorMessage: "Partner user with such email already exists"})
                        return
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 422:
                        if (err.response.data !== null) {
                            this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        } else {
                            this.setState({errorMessage: "Validation error"});
                        }
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: err});
            return;
        }
        this.props.history.push('/partner/'+ this.state.partnerId +'/users')
    }

    render() {
        if (!this.state.partnerName) {
            return <div>Loading data...</div>
        }

        return <Layout activeMenu="partner">
            <PartnerNav activeKey="users" id={this.state.partnerId}/>
            <div style={divStyle}>
                <h1>New user for partner "{this.state.partnerName}"</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}


                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="6">Name</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="name" defaultValue={this.state.data.name}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="6">Email</Form.Label>
                        <Col sm="6">
                            <Form.Control type="email" name="email" defaultValue={this.state.data.email}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="6">Status</Form.Label>
                        <Col sm="6">
                            <Select
                                name="status"
                                options={statuses}
                                defaultValue={{
                                    value: this.state.data.status,
                                    label: this.capitalize(this.state.data.status)
                                }}
                                onChange={this.handleStatusChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextPass">
                        <Form.Label column sm="6">Password</Form.Label>
                        <Col sm="6">
                            <Form.Control type="password" name="pass" defaultValue={this.state.data.pass}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} controlId="formBasicCheckbox">
                        <Form.Label column sm="6">Can edit users</Form.Label>
                        <Col sm="6">
                            <Form.Check type="checkbox" name="rolesEditUsers" defaultChecked={this.state.data.rolesEditUsers}
                                        onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Button type="submit" variant="primary">Save</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default PartnerUserNew