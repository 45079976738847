import React from 'react'
import Layout from "../../component/Layout";
import AccountNav from "../../component/AccountNav";
import {get, post} from "../../utils/request";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class AccountPartner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loaded: false,
            partners: [],
            partner: {
                name: '',
                partnerId: ''
            },
            account: {
                name: '',
                partnerId: ''
            },
            data: {
                partnerId: "",
            },
            successMessage: '',
            errorMessage: '',
        };
        this.loadAccount = this.loadAccount.bind(this);
        this.loadPartners = this.loadPartners.bind(this);
        this.handlePartnerChange = this.handlePartnerChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadAccount() {
        const [res, err] = await get(this, "/account/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({account: res.data});

        if (this.state.account.partnerId) {
            const [res, err] = await get(this, "/partner/" + this.state.account.partnerId);
            if (err) {
                this.setState({errorMessage: "error: " + err});
                return
            }

            this.setState({partner: res.data});
        }
    }

    async loadPartners() {
        const [res, err] = await get(this, "/partner");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        let partners = [
            {
                value: "",
                label: "-- no partner --"
            },
        ]

        res.data.forEach((value) => {
            partners.push({
                value: value.partnerId,
                label: value.name
            })
        })

        this.setState({partners: partners});
    }

    async componentDidMount() {
        await this.loadAccount();
        await this.loadPartners();
        this.setState({loaded: true});
    }

    handlePartnerChange(event) {
        const data = this.state.data;
        data.partnerId = event.value;
        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({errorMessage: ""});

        const path = "/account/" + this.state.id + "/partner";
        const data = this.state.data;

        const [_, err] = await post(this, path, data);

        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 422:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                }
            }
            this.setState({errorMessage: "error: " + err});
            return;
        }
        this.setState({successMessage: "Account partner updated!"});
        await this.loadAccount();
    }

    render() {
        if (!this.state.loaded) {
            return <div>loading data...</div>
        }

        let ret = ""
        if (!this.state.account.partnerId) {
            ret = (<div><p><b>Set partner for account</b> {this.state.account.name}</p>
                <Form.Group as={Row} controlId="formPartnerId">
                    <Form.Label column sm="3">Partner</Form.Label>
                    <Col sm="9">
                        <Select
                            options={this.state.partners}
                            onChange={this.handlePartnerChange}
                        />
                    </Col>
                </Form.Group>
                <Button type="submit" variant="primary">Save</Button></div>)
        } else {
            ret = <p>Partner for account {this.state.account.name}: <a
                href={"/partner/" + this.state.account.partnerId + "/view"}>{this.state.partner.name}</a></p>
        }

        return <Layout activeMenu="account">
            <AccountNav activeKey="partner" id={this.state.id}/>
            <div style={divStyle}>
                <h1>Account partner</h1>
                <br/>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {this.state.successMessage && <Alert variant="success">{this.state.successMessage}</Alert>}
                    {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                    {ret}
                </Form>
            </div>
        </Layout>;
    }
}

export default AccountPartner