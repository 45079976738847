import React from 'react'
import Tab from 'react-bootstrap/Tab'
import {Nav} from "react-bootstrap";

function PartnerNav({activeKey, id}) {
    return (
        <Tab.Container>
            <Nav variant="tabs" className="justify-content-center" activeKey={activeKey}>
                <Nav.Item>
                    <Nav.Link active={activeKey === "view"} eventKey="view" href={"/partner/" + id + "/view"}>View</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "users"} eventKey="users"  href={"/partner/" + id + "/users"}>Users</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "api"} eventKey="api"  href={"/partner/" + id + "/api"}>API</Nav.Link>
                </Nav.Item>
            </Nav>
        </Tab.Container>
    );
}

export default PartnerNav

