import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { post } from '../../utils/request'
import Layout from "../../component/Layout";
import Select from "react-select";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

const statuses = [
    {label: "Active", value: "active"},
    {label: "Disabled", value: "disabled"},
    {label: "Test", value: "test"},
];
class LocationNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {
                domain: '',
                uid: '',
                status: '',
            },
            successMessage: '',
            errorMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    capitalize(string) {
        return string ? string[0].toUpperCase() + string.slice(1) : "";
    }

    handleStatusChange(event) {
        const data = this.state.data;
        data.status = event.value;
        this.setState({data: data});
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});
        this.setState({successMessage: ""});

        const [res, err] = await post(this, "/location", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Bad request: " + err.response.message});
                        return;
                    case 409:
                        this.setState({errorMessage: "Error: Location already exists"});
                        return;
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 422:
                        if (err.response.data !== null) {
                            this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        } else {
                            this.setState({errorMessage: "Validation error"});
                        }
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({errorMessage: err});
            return;
        }
        this.props.history.push('/location')
    }

    render() {
        const { data, errorMessage, successMessage } = this.state;

        return <Layout activeMenu="location">
            <div style={divStyle}>
                <h1>Create a new location</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <Form.Group as={Row} controlId="formUid">
                        <Form.Label column sm="6">UID</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="uid" defaultValue={data.uid}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formDomain">
                        <Form.Label column sm="6">Domain</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="domain"
                                          defaultValue={data.domain}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formStatus">
                        <Form.Label column sm="6">Status</Form.Label>
                        <Col sm="6">
                            <Select
                                name="status"
                                options={statuses}
                                defaultValue={{
                                    value: data.status,
                                    label: this.capitalize(data.status)
                                }}
                                onChange={this.handleStatusChange}
                            />
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Create location</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default LocationNew