import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import {post} from '../utils/request'
import Layout from "../component/Layout";

const yup = require('yup');

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            user: '',
            pass: ''
        };
        this.validator = yup.object().shape({
            user: yup.string().required(),
            pass: yup.string().required()
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({message: ""});

        this.validator.validate(this.state)
            .catch((err) => {
                this.setState({message: err.toString()})
            });

        if (this.validator.isValidSync(this.state)) {
            const data = {
                user: this.state.user,
                pass: this.state.pass
            };

            const [res, err] = await post(this, "/signIn", data);
            if (err) {
                this.setState({message: "error: " + err});
                return;
            }
            this.setState({message: "Signed in"});
            this.props.history.push('/')
        }

    }

    render() {
        return <Layout activeMenu="signIn" notLoggedIn={true}>
            <div style={divStyle}>
                <h1>Sign in</h1>
                <Form onSubmit={e => this.handleSubmit(e)}>
                    {this.state.message && <Alert variant="danger">{this.state.message}</Alert>}
                    <Form.Group as={Row} controlId="formPlaintextUser">
                        <Form.Label column sm="2">Username</Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="user" onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formBasicPassword">
                        <Form.Label column sm="2">Password</Form.Label>
                        <Col sm="10">
                            <Form.Control type="password" name="pass" onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Sign in</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default SignIn