import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {get,  put} from '../../utils/request'
import Layout from "../../component/Layout";
import PartnerNav from "../../component/PartnerNav";
import Select from "react-select";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

const methods = [
    {label: "Disabled", value: "disabled"},
    {label: "Basic", value: "basic"}
];

class PartnerApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {},
            successMessage: '',
            errorMessage: '',
            partner: {},
        };

        this.loadData = this.loadData.bind(this);
        this.handleAuthenticationMethodChange = this.handleAuthenticationMethodChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/partner/" + this.state.id);
        if (err) {
            this.setState({errorMessage: "error: " + err});

            return;
        }

        const data = {
            authenticationMethod: res.data.apiMethod,
            username: res.data.apiUsername,
        }

        this.setState({data: data});
        this.setState({partner: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    capitalize(string) {
       return string ? string[0].toUpperCase() + string.slice(1) : "";
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    handleAuthenticationMethodChange(event) {
        const data = this.state.data;
        data.authenticationMethod = event.value;
        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const [res, err] = await put(this, "/partner/" + this.state.id + "/api", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Error: Bad request"});
                        return;
                    case 404:
                        this.setState({errorMessage: "Error: Partner not found"});
                        return;
                    case 409:
                        this.setState({errorMessage: "Error: Username already exists"});
                        return;
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 422:
                        if (err.response.data !== null) {
                            this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        } else {
                            this.setState({errorMessage: "Validation error"});
                        }
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                    case 501:
                        this.setState({errorMessage: "Authentication method not implemented"});
                        return;
                }
            }
            this.setState({errorMessage: "Error:", err});
            return;
        }
        this.props.history.push('/partner/' + this.state.id + '/view')
    }

    render() {
        const { data, errorMessage, id, partner, successMessage } = this.state;

        if (!partner.partnerId) {
            return <div>Loading data...</div>
        }
        return <Layout activeMenu="partner">
            <PartnerNav activeKey="api" id={id}/>
            <div style={divStyle}>
                <h1>Partner API edit</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form.Group as={Row} controlId="formApiMethods">
                        <Form.Label column sm="6">API method</Form.Label>
                        <Col sm="6">
                            <Select
                                name="authenticationMethod"
                                options={methods}
                                defaultValue={{
                                    value: data.authenticationMethod,
                                    label: this.capitalize(data.authenticationMethod)
                                }}
                                onChange={this.handleAuthenticationMethodChange}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formUsername">
                        <Form.Label column sm="6">Username</Form.Label>
                        <Col sm="6">
                            <Form.Control type="text" name="username" defaultValue={data.username}
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formSecret">
                        <Form.Label column sm="6">Secret (password)</Form.Label>
                        <Col sm="6">
                            <Form.Control type="password" name="secret"
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Update API</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default PartnerApi