import React from 'react';
import countryList from 'react-select-country-list'
import Select from 'react-select'

export default class CountrySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onChange: props.onChange,
            countries: countryList().getData(),
        };

        if (props.allowAll) {
            this.state.countries.unshift({"label": "All", "value": ""})
            if (!props.selected) {
                this.state.selected = {
                    value: "",
                    label: "All"
                }
            }
        }

        if (props.selected) {
            this.state.selected = {
                value: props.selected,
                label: countryList().getLabel(props.selected)
            }
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.state.onChange(event.value);
    }

    render() {
        return <Select
            options={this.state.countries}
            defaultValue={this.state.selected}
            onChange={this.handleChange}
        />
    }
}