import React from 'react';
import Tab from 'react-bootstrap/Tab';
import {Nav} from "react-bootstrap";

function LocationNav({activeKey, id}) {
    return (
        <Tab.Container>
            <Nav variant="tabs" className="justify-content-center" activeKey={activeKey}>
                <Nav.Item>
                    <Nav.Link active={activeKey === "view"} eventKey="view" href={"/location/" + id + "/view"}>View</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link active={activeKey === "key"} eventKey="key"  href={"/location/" + id + "/edit/key"}>Key</Nav.Link>
                </Nav.Item>
            </Nav>
        </Tab.Container>
    );
}

export default LocationNav

