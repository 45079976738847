import React from 'react'

import Menu from './Menu'

function Layout ({activeMenu, notLoggedIn, children}) {
    return <>
        {!notLoggedIn && <Menu activeMenu={activeMenu} />}
        <div style={{
                margin: '4px 40px 40px 40px',
        }}>{children}</div>
    </>
}

export default Layout;